import { FC, useEffect, useState } from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, IconButton, MessageBarType, Modal, PrimaryButton, Text, TextField } from '@fluentui/react';
import AutocompleteInput from '../../shared/AutocompleteInput';
import { massUploadOptions } from './consts';
import { IMassUploadProps } from './IMassUploadProps';
import { useBoolean } from '@fluentui/react-hooks';
import apiService from '../../api';

import styles from './MassUpload.module.scss';
import useNotifications from '../../hooks/useNotifications';
import { IMassUploadState } from './IMassUploadState';
import moment from 'moment';
import classNames from 'classnames';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { useSelector } from 'react-redux';
import { customerInformationSelector, customerSelector } from '../../redux/recordKeepingSlice';
import { downloadFile } from '../PrintingModal/consts';

const MassUpload: FC<IMassUploadProps> = ({isModalOpen, hideModal, formType, fetch, period, location, regDate, getUploadRunningStatus}) => {
  const [state, setState] = useState<IMassUploadState>({ loading: false, customersList: [], locationsList: [], billingPeriodList: [] });
  const [selectedFile, setSelectedFile] = useState<any | Blob>(null);
  const [billingPeriodId, setBillingPeriodId] = useState<any>(period);
  const [showUploadConfirmation, { toggle: toggleUploadConfirmation }] = useBoolean(false);
  const [runningStatus, setRunningStatus] = useState<string>('IDLE');

  const { addNotification } = useNotifications();
  const { id: customerId, customerName } = useSelector(customerSelector);
  const [locationId, setLocationId] = useState<any>(location);
  const [registeredDate, setRegisteredDate] = useState<any>(regDate);
  const { billBy } = useSelector(customerInformationSelector);

  const hanldeUpload = () => {
    toggleUploadConfirmation();
    if (formType === massUploadOptions[0].name) {
      uploadPostMonthlyMiles();
    } else if (formType === massUploadOptions[1].name) {
      uploadB73Format();
    } else if (formType === massUploadOptions[2].name) {
      uploadMassDetails();
    } else if (formType === massUploadOptions[3].name) {
      uploadRegisteredVehicles();
    }
  };

  const convertToDate = (val: any) => {
    const convertedValue = val ? moment(val).format('MM/DD/YYYY') : null;
    if(typeof val === 'number' || convertedValue === 'Invalid date') {
      return val;
    } else {
      return convertedValue;
    }
  };

  const fetchLocations = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const { data }: any = await apiService.getLocationsByCustomerId(
        customerId,
        { pageSize: 5000, pageNumber: 1 },
        {},
      );
      setState(
        prev => ({
          ...prev,
          locationsList: data.data,
        }),
      );
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Locations fetching error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchBillingPeriod = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.getBillingPeriodList(
        customerId,
        { isMonthlyMiles: true },
      );
      setState(
        prev => ({
          ...prev,
          billingPeriodList: data.data,
        }),
      );
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching Billing Period error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const parseErrors = (arrayOfErrors: Array<any>) => {
    let line = '';
    if (arrayOfErrors.length) {
      arrayOfErrors.forEach((element, index) => {
        line += `${++index}) ${element.message} \n`;
      });
    }
    return line;
  };


  const uploadB73Format = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.massUpload.uploadB73Format(
        customerId,
        locationId,
        selectedFile,
      );
      fetch();
    } catch (e: any) {
      const { response } = e;
      if (response?.data?.state) {
        addNotification({
          text: `Uploading Tire Change error: ${parseErrors(response?.data?.state)}`,
          type: MessageBarType.error,
        });
      } else {
        addNotification({
          text: `Uploading Tire Change error: ${response?.data?.message}`,
          type: MessageBarType.error,
        });
      }
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
      hideModal();
    }
  };

  const uploadPostMonthlyMiles = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.massUpload.uploadPostMonthlyMiles(
        customerId,
        locationId == null ? '' : locationId,
        billingPeriodId,
        selectedFile,
      );
      fetch();
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 499:
          return addNotification({
            text: `Unable to upload: ${response.data.message}`,
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Saving error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
      hideModal();
    }
  };

  const uploadMassDetails = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data: { message } }: any = await apiService.massUpload.uploadMassDetails(
        customerId,
        locationId,
        selectedFile,
      );
      fetch();
      await uploadMassDetailsStatus();
      if (runningStatus == 'IDLE') {
        addNotification({ text: message, type: MessageBarType.success });
      }
    } catch (e: any) {
      const { response } = e;
      if (response?.data?.state) {
        addNotification({
          text: `Uploading Mass Details error: ${parseErrors(response?.data?.state)}`,
          type: MessageBarType.error,
        });
      } else {
        addNotification({
          text: `Uploading Mass Details error: ${response?.data?.message}`,
          type: MessageBarType.error,
        });
      }
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
      hideModal();
    }
  };

  const uploadMassDetailsStatus = async () => {
    let timeOut = null;
    try {
      const { data: { runStatus, errorMsg } }: any = await apiService.massUpload.uploadMassDetailsStatus(
        customerId,
        locationId,
      );
      if (timeOut) clearInterval(timeOut);
      if (runStatus == 'RUNNING'){
        timeOut = setTimeout(() => uploadMassDetailsStatus(), 5000);
        addNotification({ text: 'Upload in Progress', type: MessageBarType.success });
      } 
      if (runStatus == 'COMPLETED'){
        addNotification({ text: 'Upload Completed', type: MessageBarType.success });
      }
      if (runStatus == 'FAILED'){
        addNotification({ text: 'Upload Error: ' + errorMsg, type: MessageBarType.error });
      }
      fetch();      
      setRunningStatus(runStatus);
      getUploadRunningStatus(runStatus);
    } catch (e: any) {
      addNotification({ text: 'Error:' + e, type: MessageBarType.error });
    }
  };

  const uploadRegisteredVehicles = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data: { message } }: any = await apiService.massUpload.uploadRegisteredVehicles(
        customerId,
        locationId,
        registeredDate,
        selectedFile,
      );
      await uploadRegisteredVehiclesStatus();
      if (runningStatus == 'IDLE') {
        addNotification({ text: message, type: MessageBarType.success });
      }
    } catch (e: any) {
      const { response } = e;
      if (response?.data?.state) {
        addNotification({
          text: `Uploading Registered Vehicles error: ${parseErrors(response?.data?.state)}`,
          type: MessageBarType.error,
        });
      } else {
        addNotification({
          text: `Uploading Registered Vehicles error: ${response?.data?.message}`,
          type: MessageBarType.error,
        });
      }
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
      hideModal();
    }
  };

  const uploadRegisteredVehiclesStatus = async () => {
    let timeOut = null;
    try {
      const { data: { runStatus, errorMsg } }: any = await apiService.massUpload.uploadRegisteredVehiclesStatus(
        customerId,
        locationId,
        registeredDate,
      );
      if (timeOut) clearInterval(timeOut);
      if (runStatus == 'RUNNING'){
        timeOut = setTimeout(() => uploadRegisteredVehiclesStatus(), 5000);
        addNotification({ text: 'Upload in Progress', type: MessageBarType.success });
      } 
      if (runStatus == 'COMPLETED'){
        addNotification({ text: 'Upload Completed', type: MessageBarType.success });
      }
      if (runStatus == 'FAILED'){
        addNotification({ text: 'Upload Error: ' + errorMsg, type: MessageBarType.error });
      }
      fetch();      
      setRunningStatus(runStatus);
      getUploadRunningStatus(runStatus);
    } catch (e: any) {
      addNotification({ text: 'Error:' + e, type: MessageBarType.error });
    }
  };


  const downloadTemplate = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.commonAPI.downloadTemplate(
        getServiceName(formType,massUploadOptions),
      );
      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, 'Excel');
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Download Template error: ${parseErrors(response?.data?.state)}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const getServiceName = (formType:string, massUploadOptions:any) => {
    for (const option of massUploadOptions) {
      if (formType === option.name) {
        return option.serviceName;
      }
    }
  };

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    toggleUploadConfirmation();
  };

  const handleCancel = () => {
    setSelectedFile(null);
    toggleUploadConfirmation();
  };
  
  useEffect(() => {
    setLocationId(location);
    return () => {
      setLocationId(null);
    };
  }, [location]);

  useEffect(() => {
    setRegisteredDate(regDate);
    return () => {
      setRegisteredDate(null);
    };
  }, [regDate]);

  useEffect(() => {
    if (customerId !== null) {
      fetchLocations();
      formType == massUploadOptions[0].name && fetchBillingPeriod();
    }
  }, [customerId]);

  return (
    <>
      {!state.loading ? 
        <Modal
          isOpen={isModalOpen}
          onDismiss={hideModal}
          isBlocking={false}
          containerClassName={styles.modalContainer}
        >
          <div className={styles.modalBody}>
            <div className={styles.modalHeader}>
              <Text variant="xLarge">Upload {formType}</Text>
              <IconButton
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel="Close popup modal"
                onClick={hideModal}
              />
            </div>
            <div className={styles.massUpload}>
              <PrimaryButton text="Template Download" onClick={downloadTemplate}/>
              <div className="ms-TextField">
                <AutocompleteInput
                  value={customerId}
                  list={
                    [{key: customerId, text: customerName }]
                  }
                  prefixIcon="Contact"
                  required
                  disabled
                />
              </div>
              <div className="ms-TextField">
                <AutocompleteInput
                  chooseCurrentItem={setLocationId}
                  value={locationId}
                  list={
                    state.locationsList.map(({ id, locationName }) => ({
                      key: id,
                      text: locationName,
                    }))
                  }
                  prefix=""
                  prefixIcon="POISolid"
                  emptyExpanded
                  required={formType !== massUploadOptions[2].name}
                  disabled={billBy != 'Location'}
                />
              </div>
              {
                formType == massUploadOptions[0].name ? 
                  <div className="ms-TextField">
                    <AutocompleteInput
                      chooseCurrentItem={setBillingPeriodId}
                      value={billingPeriodId}
                      list={
                        state.billingPeriodList.map(({ id, intervalStart, intervalEnd }) => ({
                          key: id,
                          text: convertToDate(intervalStart) + '-' + convertToDate(intervalEnd),
                        }))
                      }
                      prefix=""
                      emptyExpanded
                      required
                      disabled
                    />
                  </div> : null
              }                          
            </div>
            <div className={styles.modalFooter}>
              <label 
                className={
                  classNames(
                    styles.customButton, 
                    (customerId === null || (formType == massUploadOptions[0].name && billingPeriodId === null)) && styles.disabled,
                  )
                }
              >
                <TextField type="file" onChange={changeHandler} className={styles.upload}/>
                <span>
                  <span>Upload</span>
                </span>
              </label>
            </div> 
          </div>
        
        </Modal> : <LoadingScreen />
      }
      <Dialog
        hidden={!showUploadConfirmation}
        onDismiss={toggleUploadConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: `Confirm File Upload for 
            ${formType} - 
            ${customerName} -  
            ${locationId ? state.locationsList.find((location) => location.id === locationId)?.locationName +  ' - ' : ''}
            ${formType == massUploadOptions[0].name && billingPeriodId !== null ? convertToDate(state.billingPeriodList.find((billingPeriod) => billingPeriod.id === billingPeriodId)?.intervalStart) + '-' + convertToDate(state.billingPeriodList.find((billingPeriod) => billingPeriod.id === billingPeriodId)?.intervalEnd) : ''}`,
        }}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteButton" onClick={hanldeUpload} text="Upload" />
          <DefaultButton onClick={handleCancel} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default MassUpload;