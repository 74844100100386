import React, { FC, FormEvent, ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import { DefaultButton, Dropdown, IColumn, Icon, IconButton, IDropdownOption, IStackStyles, Link, MessageBarType, PrimaryButton, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import useNotifications from '../../../../hooks/useNotifications';
import apiService from '../../../../api';
import { sortOrder } from '../../../../consts/sortOrder';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';

import SeparatorGy from '../../../SeparatorGy/SeparatorGy';
import Pagination from '../../../Pagination/Pagination';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import PrintingModal from '../../../PrintingModal/PrintingModal';
import { IPaginationProps } from '../../../Pagination/IPaginationProps';
import { downloadFile, printingTypes } from '../../../PrintingModal/consts';

import PermissionCopyingModal from './components/PermissionCopyingModal/PermissionCopyingModal';
import PermissionEditingModal from './components/PermissionEditingModal/PermissionEditingModal';
import ExpandedPermissionModal from './components/ExpandedPermissionModal/ExpandedPermissionModal';
import { IPermissionRolesProps } from './IPermissionRolesProps';
import { IPermissionRolesState } from './IPermissionRolesState';
import { columns, pageSizes } from './consts';

import styles from './PermissionRoles.module.scss';

const PermissionRoles: FC<IPermissionRolesProps> = (): ReactElement => {
  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const [state, setState] = useState<IPermissionRolesState>({
    items: [],
    loading: false,
    foundCount: 0,
  });
  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });
  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(columns);
  const [copyingModalState, setCopyingModalState] = useState<any>({
    show: false,
    selectedRole: null,
  });
  const [editingModalState, setEditingModalState] = useState<any>({
    show: false,
    selectedRole: null,
  });
  const [expandedInfoModalState, setExpandedInfoModalState] = useState<any>({
    show: false,
    selectedRole: null,
  });
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);

  const { addNotification } = useNotifications();
  const { hasPermission } = useUserPermissions();

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const tableIconProps: Partial<IStackStyles> = {
    root: {
      fontSize: '24px',
      color: '#004EA8',
    },
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const requestData = {
        sortOrder,
        pagination: null,
        filters: null,
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.management.generateAuthorizationReportExcel(requestData) :
        await apiService.management.generateAuthorizationReportPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const getSortOrder = () => {
    const { fieldName, isSortedDescending } = columnsState.find(({ isSorted }) => isSorted);
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetch = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { data }: any = await apiService.management.getRoles(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        sortOrder,
      );
      const foundCount = data.total.found;
      const items = data.data;
      setState((prev: any) => ({ ...prev, items, foundCount, selectedItems: [] }));
      setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
    } catch (e: any) {
      addNotification({
        text: `Roles fetching error: ${e?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const accessToAdminPage = hasPermission('Admin.ViewPage');

  useEffect(() => {
    if (
      !copyingModalState.show &&
      !editingModalState.show &&
      !expandedInfoModalState.show
    )
      fetch();
  }, [
    paginationProps.current,
    copyingModalState.show,
    editingModalState.show,
    expandedInfoModalState.show,
    countOnPage,
    columnsState,
  ]);

  return (
    <>
      <div className="ms-Grid">
        <div className={classNames('ms-Grid-row', styles.mainInfoBlock)}>
          <div className={classNames('ms-Grid-col', 'ms-sm4')}>
            <Icon iconName='Group' className={styles.mainIcon} />
            <Text variant='xLarge'>Permission Roles</Text>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm3')}></div>
          <div className={classNames('ms-Grid-col', 'ms-sm2', styles.tooltipIconWrapper)}>
            <Icon className={styles.errorIcon} iconName='Error' />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm3')}>
            <Text block>
              Access management is handled in GO Access. If you want to add or remove access to the users, please visit:
            </Text>
            <Link target="_blank" href='https://goaccess.goodyear.com:8443/identityiq/home.jsf'>GOAccess - Home (goodyear.com)</Link>
          </div>
        </div>
        <SeparatorGy />
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm4', styles.textBlock)}>
            <Icon className={styles.errorIcon} iconName='Error' />
            <div>
              <Text block>
                Create and edit permission roles in this screen. To add or remove permissions to specific module tabs, you must:
              </Text>
              <Text className={styles.paragraph} block>
                1. Click the 'View' icon for the role you want to modify.
              </Text>
              <Text className={styles.paragraph} block>
                2. Check or uncheck to edit permissions.
              </Text>
            </div>
          </div>
        </div>

        <div className={classNames('ms-Grid-row', styles.tableHeading)}>
          <div className={classNames('ms-Grid-col', 'ms-sm4')}>
            <div>
              <Icon iconName='Group' className={styles.mainIcon} />
              <Text variant='large'>Permission Roles</Text>
            </div>
            <div>
              <Text variant="large">Show # of rows:&nbsp;</Text>
              <Dropdown
                options={pageSizes.map(pageSize => ({
                  key: pageSize,
                  text: pageSize.toString(),
                }))}
                defaultSelectedKey={pageSizes[0]}
                selectedKey={countOnPage?.key}
                onChange={onChangeCountOnPage}
              />
            </div>
            <div>
              <PrimaryButton
                onClick={() => setEditingModalState({ show: true, selectedRole: null })}
                text="Create New Role"
                disabled={!accessToAdminPage.isWrite}
              />
            </div>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm8')}>
            <Text variant="xLarge">{state.foundCount} found</Text>
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <table>
              <thead>
                <tr>
                  {
                    columnsState.map(item => (
                      <th
                        key={item.name}
                        className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined}
                        onClick={() => onColumnClick(item)}
                      >
                        {item.name}
                      </th>
                    ))
                  }
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  state.items.map((item) => (
                    <tr key={item.id} className={styles.trBasic}>
                      {
                        columnsState.map((column) => (
                          <td>
                            {item[column.fieldName]}
                          </td>
                        ))
                      }
                      <td className={styles.pageFooter}>
                        <IconButton
                          iconProps={{ iconName: 'OpenInNewWindow', styles: tableIconProps }}
                          onClick={() => setExpandedInfoModalState({ show: true, selectedRole: item })}
                          disabled={!accessToAdminPage.isWrite}
                        />
                        <IconButton
                          iconProps={{ iconName: 'Edit', styles: tableIconProps }}
                          onClick={() => setEditingModalState({ show: true, selectedRole: item })}
                          disabled={!accessToAdminPage.isWrite}
                        />
                        <IconButton
                          iconProps={{ iconName: 'Copy', styles: tableIconProps }}
                          onClick={() => setCopyingModalState({ show: true, selectedRole: item })}
                          disabled={!accessToAdminPage.isWrite}
                        />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>

        {state.foundCount > countOnPage?.key && (
          <>
            <SeparatorGy />
            <Pagination {...paginationProps} />
          </>
        )}

        <SeparatorGy />
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.pageFooter)}>
            <DefaultButton
              id="generateAuthorizationReportButton"
              text='Generate Authorization Report'
              onClick={toggleShowPrintExport}
            />
          </div>
        </div>
      </div>
      <PermissionCopyingModal
        isModalOpen={copyingModalState.show}
        roleData={copyingModalState.selectedRole}
        hideModal={() => setCopyingModalState({ show: false, selectedRole: null })}
      />
      <PermissionEditingModal
        isModalOpen={editingModalState.show}
        hideModal={() => setEditingModalState({ show: false, selectedRole: null })}
        roleData={editingModalState.selectedRole}
      />
      <ExpandedPermissionModal
        isModalOpen={expandedInfoModalState.show}
        hideModal={() => setExpandedInfoModalState({ show: false, selectedRole: null })}
        roleData={expandedInfoModalState.selectedRole}
      />
      {state.loading && <LoadingScreen />}
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
    </>
  );
};

export default PermissionRoles;
