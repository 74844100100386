import React, { FC, ReactElement, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { get, isEmpty, isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';
import { Checkbox, DatePicker, DefaultButton, Dropdown, IconButton, MessageBarType, Pivot, PivotItem, PrimaryButton, TextField, Text } from '@fluentui/react';

import apiService from '../../../../../../../../api';
import useNotifications from '../../../../../../../../hooks/useNotifications';
import { customerInformationSelector, customerSelector, locationSelector } from '../../../../../../../../redux/recordKeepingSlice';

import LoadingScreen from '../../../../../../../LoadingScreen/LoadingScreen';

import FootprintModal from './FootprintModal/FootprintModal';

import { IGeneralRegisterVehicleState } from './IGeneralRegisterVehicleState';
import { IGeneralRegisterVehicleProps } from './IGeneralRegisterVehicleProps';
import { emptyGeneralForm, typeOfVehicle } from './consts';
import { IGeneralForm } from './IGeneralForm';

import { useUserPermissions } from '../../../../../../../../hooks/useUserPermissions';
import { auth_actionOnVehicles_registerVehicles} from '../../../../../../../../consts/programKeys';

import styles from './GeneralRegisterVehicle.module.scss';
import { transformDate } from '../../../../../../../../shared/transformDate';
import MassUpload from '../../../../../../../MassUpload/MassUpload';


const GeneralRegisterVehicle: FC<IGeneralRegisterVehicleProps> = ({ vehicleInfo, handleFetching, openAddingMode }): ReactElement => {
  const [state, setState] = useState<IGeneralRegisterVehicleState>({
    loading: false,
    models: [],
    makes: [],
    wheels: [],
    statuses: [],
  });
  const [currentTab, setCurrentTab] = useState<any>(
    typeOfVehicle.singleVehicle,
  );
  const [parsedSubmittingErrors, setParsedSubmittingErrors] = useState<any>([]);
  const [generalForm, setGeneralForm] = useState<IGeneralForm>(emptyGeneralForm);
  const [showFootprintsModal, { toggle: toggleShowFootprintsModal }] = useBoolean(false);

  const isAddingMode = useMemo(() => isNil(vehicleInfo), [vehicleInfo]);

  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);
  const { billBy, lastMileageRun } = useSelector(customerInformationSelector);
  const { addNotification } = useNotifications();

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_actionOnVehicles_registerVehicles);

  const [showUpload, { toggle: toggleShowUpload }] = useBoolean(false);
  const [uploadRunningStatus, setUploadRunningStatus] = useState<string>('IDLE');
  const [registeredDate, setRegisteredDate] = useState<any>(null);

  const fetchValues = async () => {
    setState((prev: any) => ({ ...prev, loading: true }));
    try {
      const { data: makes } = await apiService.registerVehicles.getMake(
        customerId,
      );
      const { data: wheels } = await apiService.registerVehicles.getWheels(
        customerId,
        billBy == 'Location' ? locationId : null,
      );

      setState((prev: any) => ({ ...prev, makes, wheels }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching values error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleLinkClick = (item?: PivotItem) => setCurrentTab(item?.props.itemKey);

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  const onChangeCheckbox = (event: any) => {
    const fieldToBeUpdate: any = event.target.name;

    setGeneralForm((prev: any) => ({ ...prev, [fieldToBeUpdate]: !get(prev, fieldToBeUpdate) }));
  };

  const onChangeField = (field: any, value: any, regExp?: RegExp) =>
    setGeneralForm((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));

  const parseSubmittingErrors = (field: string) => {
    const customError = parsedSubmittingErrors?.filter((error: { field: string; }) => error.field === field)[0];
    if (customError) {
      return customError.message;
    }
  };

  const setFootprintOf = (footprintOf: any) => {
    setGeneralForm((prev) => ({ ...prev, footprintOf, whlPos: footprintOf.wheels, type: footprintOf.vehType }));
    toggleShowFootprintsModal();
  };

  const handleReinstate = async () => {
    setState((prev: any) => ({ ...prev, loading: true }));
    try {
      await apiService.registerVehicles.reinstate(
        vehicleInfo.id,
        transformDate(moment(vehicleInfo.regDate).format('MM/DD/YYYY')),
        customerId,
      );
      addNotification({
        text: 'Reinstating was successfully completed',
        type: MessageBarType.success,
      });
      setParsedSubmittingErrors([]);
      handleFetching();
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedSubmittingErrors(response.data.state);
          return addNotification({
            text: 'Unable to reinstate.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Reinstating error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleDelete = async () => {
    setState((prev: any) => ({ ...prev, loading: true }));
    try {
      await apiService.registerVehicles.delete(
        vehicleInfo.id,
        vehicleInfo.vehNo,
        customerId,
      );
      addNotification({
        text: 'Selected vehicle was successfully removed.',
        type: MessageBarType.success,
      });
      setParsedSubmittingErrors([]);
      handleFetching();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Deleting error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleUnreg = async () => {
    setState((prev: any) => ({ ...prev, loading: true }));
    try {
      await apiService.registerVehicles.unreg(
        vehicleInfo.id,
        transformDate(moment(vehicleInfo.regDate).format('MM/DD/YYYY')),
        customerId,
      );
      addNotification({
        text: 'Unregistering was successfully completed.',
        type: MessageBarType.success,
      });
      setParsedSubmittingErrors([]);
      handleFetching();
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedSubmittingErrors(response.data.state);
          return addNotification({
            text: 'Unable to unregister.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Unregistering error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleUpdate = async () => {
    setState((prev: any) => ({ ...prev, loading: true }));
    try {
      const {
        footprintOf,
        whlPos,
        regDate,
        putCOT,
        ...restProps
      } = generalForm;

      const preparedData = {
        ...restProps,
        id: vehicleInfo.id,
        footprintOf: footprintOf.id,
        noOfWhlPos: whlPos,
        vehNoRange: currentTab === typeOfVehicle.series ? 1 : 0,
        regDate: transformDate(moment(regDate).format('MM/DD/YYYY')),
      };

      await apiService.registerVehicles.update(
        preparedData,
        customerId,
      );
      addNotification({
        text: 'Selected vehicle was successfully updated.',
        type: MessageBarType.success,
      });
      setParsedSubmittingErrors([]);
      handleFetching();
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 400:
          setParsedSubmittingErrors(response.data.state);
          return addNotification({
            text: 'Unable to update.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Updating error: ${response.data.message}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleAdd = async () => {
    setState((prev: any) => ({ ...prev, loading: true }));
    try {
      const {
        footprintOf,
        regDate,
        whlPos,
        ...restProps
      } = generalForm;

      const preparedData = {
        ...restProps,
        footprintOf: footprintOf.id,
        regDate: transformDate(moment(regDate).format('MM/DD/YYYY')),
        noOfWhlPos: whlPos,
        vehNoRange: currentTab === typeOfVehicle.series ? 1 : 0,
      };
      setParsedSubmittingErrors([]);
      await apiService.registerVehicles.add(
        preparedData,
        customerId,
        locationId,
      );
      addNotification({
        text: 'Adding was successfully completed.',
        type: MessageBarType.success,
      });
      handleFetching();
    } catch (e: any) {
      const { response } = e;
      switch (get(response, 'status', null)) {
        case 400:
          setParsedSubmittingErrors(response.data.state);
          return addNotification({
            text: 'Unable to add.',
            type: MessageBarType.error,
          });
        default:
          addNotification({
            text: `Adding error: ${get(response, 'data.message', '')}`,
            type: MessageBarType.error,
          });
      }
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleClear = () => {
    setGeneralForm(emptyGeneralForm);
    openAddingMode();
  };

  useEffect(() => {
    fetchValues();
  }, []);

  useEffect(() => {
    async function fetchModels() {
      const { data: models } = await apiService.registerVehicles.getModel(
        customerId,
        generalForm?.make,
      );
      setState((prev: any) => ({ ...prev, models }));
    }
    if (generalForm?.make !== ''){
      fetchModels();
    }
  }, [generalForm?.make]);

  useEffect(() => {
    if (!isNil(vehicleInfo)) {
      const { make, model, whlPos, year, vehNo, regDate, type, footprintOf } = vehicleInfo;
      setRegisteredDate(transformDate(moment(regDate).format('MM/DD/YYYY')));
      setGeneralForm({ ...emptyGeneralForm, make, model, whlPos, year, vehNoFrom: vehNo, regDate: new Date(regDate), type, footprintOf });
    } else {
      setGeneralForm({ ...emptyGeneralForm, regDate: new Date(lastMileageRun)});      
      setRegisteredDate(transformDate(moment(lastMileageRun).format('MM/DD/YYYY')));
    }
    setParsedSubmittingErrors([]);
  }, [
    vehicleInfo,
    customerId,
    lastMileageRun,
  ]);

  return (
    <>
      <div className={classNames('ms-Grid', styles.grid)}>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            <Checkbox
              id="put-oot-on-sp"
              label="Put COT on SP"
              checked={generalForm.putCOT}
              name="putCOT"
              onChange={onChangeCheckbox}
              disabled={!isAddingMode}
            />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            {/* <Checkbox
              id="require-unit-conversion"
              label="Require Unit Conversion"
              checked={generalForm.requireUnitConv}
              name="requireUnitConv"
              onChange={onChangeCheckbox}
            /> */}
            <Text variant='mediumPlus' className={styles.statusWrapper}>UPLOAD STATUS: { uploadRunningStatus } </Text>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm12', 'tabulatorWrapper')}>
            <Pivot
              aria-label="Select type"
              selectedKey={currentTab}
              onLinkClick={handleLinkClick}
              getTabId={getTabId}
              linkFormat="tabs"
            >
              <PivotItem
                headerText={typeOfVehicle.series}
                itemKey={typeOfVehicle.series}
                className={styles.inputGroup}
              >
                <TextField
                  label="Prefix"
                  maxLength={4}
                  value={generalForm.pfx}
                  onChange={(e, pfx) => onChangeField('pfx', pfx?.toUpperCase(), /^[A-Z0-9]*$/)}
                  errorMessage={parseSubmittingErrors('pfx')}
                  disabled={!isAddingMode}
                />
                <TextField
                  label="Vehicle Number"
                  maxLength={6}
                  value={generalForm.vehNoFrom}
                  onChange={(e, vehNoFrom) => onChangeField('vehNoFrom', vehNoFrom, /^[0-9]*$/)}
                  errorMessage={parseSubmittingErrors('vehNoFrom')}
                  description="From"
                  disabled={!isNil(vehicleInfo) && !isNil(vehicleInfo.status) && !isAddingMode}
                />
                <TextField
                  label="Vehicle Number"
                  maxLength={6}
                  value={generalForm.vehNoTo}
                  onChange={(e, vehNoTo) => onChangeField('vehNoTo', vehNoTo, /^[0-9]*$/)}
                  errorMessage={parseSubmittingErrors('vehNoTo')}
                  description="To"
                  disabled={!isAddingMode}
                />
                <TextField
                  label="Suffix"
                  maxLength={5}
                  value={generalForm.sfx}
                  onChange={(e, sfx) => onChangeField('sfx', sfx?.toUpperCase(), /^[A-Z0-9]*$/)}
                  errorMessage={parseSubmittingErrors('sfx')}
                  disabled={!isAddingMode}
                />
              </PivotItem>
              <PivotItem
                headerText={typeOfVehicle.singleVehicle}
                itemKey={typeOfVehicle.singleVehicle}
                className={styles.inputGroup}
              >
                <TextField
                  label="Prefix"
                  maxLength={4}
                  value={generalForm.pfx}
                  onChange={(e, pfx) => onChangeField('pfx', pfx?.toUpperCase(), /^[A-Z0-9]*$/)}
                  errorMessage={parseSubmittingErrors('pfx')}
                  disabled={!isAddingMode}
                />
                <TextField
                  label="Vehicle Number"
                  maxLength={6}
                  value={generalForm.vehNoFrom}
                  onChange={(e, vehNoFrom) => onChangeField('vehNoFrom', vehNoFrom, /^[0-9]*$/)}
                  errorMessage={parseSubmittingErrors('vehNoFrom')}
                  disabled={!isNil(vehicleInfo) && !isNil(vehicleInfo.status) && !isAddingMode}
                />
                <TextField
                  label="Suffix"
                  maxLength={5}
                  value={generalForm.sfx}
                  onChange={(e, sfx) => onChangeField('sfx', sfx?.toUpperCase(), /^[A-Z0-9]*$/)}
                  errorMessage={parseSubmittingErrors('sfx')}
                  disabled={!isAddingMode}
                />
              </PivotItem>
            </Pivot>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            <Dropdown
              label="Make"
              options={state.makes.map((make: any) => ({ key: make, text: make }))}
              selectedKey={generalForm.make}
              onChange={(ev, { key: make }: any) => onChangeField('make', make)}
              errorMessage={parseSubmittingErrors('make')}
            />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            <Dropdown
              label="Model"
              options={state.models.map((model: any) => ({ key: model, text: model }))}
              selectedKey={generalForm.model}
              onChange={(ev, { key: model }: any) => onChangeField('model', model)}
              errorMessage={parseSubmittingErrors('model')}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm6', styles.inputGroup, styles.inputWithButton)}>
            <TextField
              label="Footprint of"
              errorMessage={parseSubmittingErrors('footprintOf')}
              value={get(generalForm.footprintOf, 'footprintName', '')}
            />
            <IconButton
              iconProps={{ iconName: 'Search' }}
              onClick={toggleShowFootprintsModal}
            />
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            <TextField
              label="Type"
              value={generalForm.type}
              errorMessage={parseSubmittingErrors('type')}
              disabled
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm6', styles.inputGroup, styles.inputWithButton)}>
            <DatePicker
              label="Registered Date"
              value={generalForm.regDate}
              formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
              disabled
            />
            {/* <Checkbox
              className={styles.bigCheckbox}
              checked={generalForm.checkBoxRegDate}
              name="checkBoxRegDate"
              onChange={onChangeCheckbox}
            /> */}
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            <div className="ms-Grid">
              <div className="ms-Grid-row">
                <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                  <TextField
                    label="# of Wheel Positions"
                    value={generalForm.whlPos}
                    errorMessage={parseSubmittingErrors('whlPos')}
                    disabled
                  />
                </div>
                <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                  <TextField
                    label="Year"
                    maxLength={4}
                    value={generalForm.year}
                    onChange={(e, year) => onChangeField('year', year, /^[0-9]*$/)}
                    errorMessage={parseSubmittingErrors('year')}
                    disabled={!isNil(vehicleInfo) && !isNil(vehicleInfo.status) && !isAddingMode}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm12', styles.buttonsGroup)}>
            {/* Hiding the Reinstate button as per Bug 10874 - Will unhide after the new feature is done
            
            <DefaultButton
              text="Reinstate"
              id="reinstateButton"
              onClick={handleReinstate}
              disabled={isAddingMode || (vehicleInfo.status != 'UNR' && vehicleInfo.status != 'SLD' && vehicleInfo.status != 'XFR') || !userPermissions.isWrite}
            /> */}
            <DefaultButton
              id="uploadButton"
              onClick={toggleShowUpload}
              text="Upload"
              disabled={!userPermissions.isWrite || uploadRunningStatus == 'RUNNING'}
            />
            <DefaultButton
              text="Delete"
              id="deleteButton"
              onClick={handleDelete}
              disabled={isAddingMode || !userPermissions.isWrite}
            />
            <DefaultButton
              text="Unreg"
              id="unregButton"
              onClick={handleUnreg}
              disabled={isAddingMode || !userPermissions.isWrite}
            />
            <DefaultButton
              text="Clear"
              id="clearButton"
              onClick={handleClear || !userPermissions.isWrite}
            />
            <PrimaryButton
              text="Update"
              id="updateButton"
              onClick={handleUpdate}
              disabled={isAddingMode || !userPermissions.isWrite}
            />
            <PrimaryButton
              text="Add"
              id="addButton"
              onClick={() => handleAdd()}
              disabled={!isAddingMode || isAddingMode && (isNil(generalForm.footprintOf) || isNil(generalForm.regDate) || isEmpty(generalForm.whlPos))
              || (billBy == 'Location' && !locationId) || !userPermissions.isWrite}
            />
          </div>
        </div>
      </div>
      <MassUpload
        isModalOpen={showUpload}
        hideModal={toggleShowUpload}
        formType={'Registered Vehicles'}
        fetch={handleFetching}
        regDate={registeredDate}
        location={locationId}
        getUploadRunningStatus={setUploadRunningStatus}/>
      <FootprintModal
        isModalOpen={showFootprintsModal}
        hideModal={toggleShowFootprintsModal}
        onSubmit={setFootprintOf}
        defaultSelected={generalForm.footprintOf}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default GeneralRegisterVehicle;
