import React, { FC, ReactElement } from 'react';
import { IContractDetailsTabProps } from './ContractDetailsTabProps';
import styles from './ContractDetailsTab.module.scss';

import { Text } from '@fluentui/react';
import classNames from 'classnames';
import { get } from 'lodash';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import moment from 'moment';

const ContractDetailsTab: FC<IContractDetailsTabProps> = ({details}): ReactElement => {
  return (
    <div className={classNames(styles.container)}>
      <div className={classNames(styles.infoBlocks)}>
        <div className="infoBlock">
          <Text variant="xLarge" className={styles.highlight}>Contract Details</Text>
          <div className={classNames(styles.infoBlockData)}>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Customer Prefix:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'contract.brandPrefix', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Contract #:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'contract.contractNo', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Status:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'contract.status', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Start Date:</Text>
              <Text variant="large" className={styles.highlight}>{details?.contract.startDate ? moment(details.contract.startDate).format('MM/DD/YYYY') : 'N/A'}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Actual End Date:</Text>
              <Text variant="large" className={styles.highlight}>{details?.contract.actualEndDate ? moment(details.contract.actualEndDate).format('MM/DD/YYYY') : 'N/A'}</Text>
            </div>
          </div>
        </div>
        <div className="infoBlock">
          <Text variant="xLarge" className={styles.highlight}>Order Details</Text>
          <div className={classNames(styles.infoBlockData)}>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Order #:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'order.orderNo', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>DL #:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'order.dlNo', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Invoice #:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'order.invoiceNo', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Ship To OE M #:</Text>
              <Text variant="large" className={styles.highlight}>
                {get(details, 'order.shipToOEMNo', 'N/A')}
              </Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Ordered Created:</Text>
              <Text variant="large" className={styles.highlight}>
                {details?.order.orderedCreated ? moment(details.order.orderedCreated).format('MM/DD/YYYY') : 'N/A'}
              </Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Date Shipped:</Text>
              <Text variant="large" className={styles.highlight}>
                {details?.order.dateShipped ? moment(details.order.dateShipped).format('MM/DD/YYYY') : 'N/A'}
              </Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Date Recieved:</Text>
              <Text variant="large" className={styles.highlight}>
                {details?.order.dateRecieved ? moment(details.order.dateRecieved).format('MM/DD/YYYY') : 'N/A'}
              </Text>
            </div>
          </div>
        </div>
        <div className="infoBlock">
          <Text variant="xLarge" className={styles.highlight}>Transfer Details</Text>
          <div className={classNames(styles.infoBlockData)}>
            <Text variant="xLarge" className={styles.highlight}>Transfer From:</Text>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Customer Name:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'transfer.fromCustomerName', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Customer Prefix:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'transfer.fromCustomerPrefix', 'N/A')}</Text>
            </div>
            <Text variant="xLarge" className={styles.highlight}>Transfer To:</Text>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Customer Name:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'transfer.toCustomerName', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Customer Prefix:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'transfer.toCustomerPrefix', 'N/A')}</Text>
            </div>
            <SeparatorGy alignContent="end" />
            <Text variant="xLarge" className={styles.highlight}>Transfer as Brand #:</Text>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Prefix:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'transfer.prefix', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Brand Number:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'transfer.brandNo', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Suffix:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'transfer.suffix', 'N/A')}</Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractDetailsTab;
