import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown, IColumn,
  IconButton,
  IDropdownOption, IDropdownStyles, IIconProps, MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { get, isEmpty, isNil } from 'lodash';
import moment from 'moment';
import { FC, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import apiService from '../../../../../../api';
import { auth_tireOrder_tireOrders } from '../../../../../../consts/programKeys';
import { sortOrder } from '../../../../../../consts/sortOrder';
import useNotifications from '../../../../../../hooks/useNotifications';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { clearRecordKeeping, customerSelector, isRkUsageSelector, locationSelector } from '../../../../../../redux/recordKeepingSlice';
import { clearTireOrdering, contractInformationSelector, orderCodeSelector, setActiveSubTab, setActiveTab, setContractInformation, setInTransitOrderCode } from '../../../../../../redux/tireOrderingSlice';
import DialogComponent from '../../../../../../shared/DialogComponent';
import LoadingScreen from '../../../../../LoadingScreen/LoadingScreen';
import { IPaginationProps } from '../../../../../Pagination/IPaginationProps';
import Pagination from '../../../../../Pagination/Pagination';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';
import { tireOrderTabs } from '../../../../consts';
import { cancelReasons, emptyFilters, OTHERS, pageSizes, tableColumns } from './consts';
import EditingModalWindow from './EditingModalWindow/EditingModalWindow';
import FilterModalWindow from './FilterModalWindow/FilterModalWindow';
import { IFiltersState } from './IFiltersState';
import { ITireOrdersProps } from './ITireOrdersProps';
import { ITireOrdersState } from './ITireOrdersState';
import styles from './TireOrders.module.scss';


const filterIcon: IIconProps = { iconName: 'Equalizer' };

const TireOrders: FC<ITireOrdersProps> = () => {
  const [state, setState] = useState<ITireOrdersState>({
    items: [],
    selectedItem: null,
    loading: false,
    foundCount: 0,
  });

  const { addNotification } = useNotifications();
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_tireOrder_tireOrders);

  const { id: customerId, customerName } = useSelector(customerSelector);
  const { id: locationId, locationCode } = useSelector(locationSelector);

  const orderCode = useSelector(orderCodeSelector);

  const isRkUsage = useSelector(isRkUsageSelector);

  const dispatch = useDispatch();

  const [paginationProps, setPaginationProps] = useState<IPaginationProps>({
    total: 0,
    current: 1,
    onChangePage: (newPage: number) => setPaginationProps((prev: any) => ({ ...prev, current: newPage })),
  });

  const contractInformation = useSelector(contractInformationSelector);

  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 260 } };

  const { inTransitTires: { subTabs } } = tireOrderTabs;

  const [countOnPage, setCountOnPage] = useState<IDropdownOption>({ key: pageSizes[0], text: pageSizes[0].toString() });
  const [columnsState, setColumnsState] = useState<Array<any>>(tableColumns);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [filterVisible, { toggle: toggleFilterVisible }] = useBoolean(false);
  const [searchStr, setSearchString] = useState<string | undefined>('');
  const [filtersState, setFiltersState] = useState<IFiltersState>(emptyFilters);
  const [isDeletingDialogVisible, { toggle: toggleDeletingConfirmation }] = useBoolean(false);
  const [isEditingOrderModalVisible, { toggle: toggleEditOrderModal }] = useBoolean(false);
  const [currentOrder, setCurrentOrder] = useState<any>(null);
  const [currentMode, setCurrentMode] = useState<any>(null);

  const [isFirstLoading, { setFalse: wasFirstLoading }] = useBoolean(true);
  const [isReturnDialogVisible, { toggle: toggleReturnConfirmation }] = useBoolean(false);
  const [showReasonStringField, setShowReasonStringField] = useState<boolean>(false);
  const [reasonOption, setReasonOption] = useState<any>(null);
  const [reasonString, setReasonString] = useState<any>(null);
  const [isContinueDialogVisible, { toggle: toggleContinueConfirmation }] = useBoolean(false);

  const inTransitTiresSubtab = [
    { key: 'Transfer', text: 'Transfer', isHidden: false },
    { key: 'Add', text: 'Add', isHidden: false },
    { key: 'Update with DOT #', text: 'Update with DOT #', isHidden: state.selectedItem?.status === 'FULFILLED' },
    { key: 'Update with DN', text: 'Update with DN', isHidden: false },
    { key: 'Record Shortage In Shipment', text: 'Record Shortage In Shipment', isHidden: false },
    { key: 'View Short in Shipment In-Transit Tires', text: 'View Short in Shipment In-Transit Tires', isHidden: false },
  ];

  const onColumnClick = (column: IColumn): void => {
    const newColumns: IColumn[] = [...columnsState];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    setColumnsState(newColumns);
  };

  const handleChangeOthersReason = (event: any, reasonString = '') => {
    setReasonString(reasonString);
  };

  const onChangeCountOnPage = (event: FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    item && setCountOnPage(item);
  };

  const getSortOrder = () => {
    const column = columnsState.find(({ isSorted }) => isSorted);
    if (isNil(column))
      return;
    const { fieldName, isSortedDescending } = column;
    return {
      column: fieldName,
      order: isSortedDescending ? sortOrder.DESC : sortOrder.ASC,
    };
  };

  const fetchContractInformation = async () => {
    setState((prev: ITireOrdersState) => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.tireOrders.getContractInformation(customerId);
      if (data?.id !== contractInformation?.id)
        dispatch(setContractInformation(data));
    } catch (e: any) {
      const { response } = e;
      if (response?.status === 404) {
        addNotification({
          text: 'Active contract is absent for selected customer',
          type: MessageBarType.error,
        });
      } else {
        addNotification({
          text: `Fetching contract information error: ${get(response, 'data.message', '')}`,
          type: MessageBarType.error,
        });
      }
    } finally {
      setState((prev: ITireOrdersState) => ({ ...prev, loading: false }));
    }
  };

  const fetch = async (byCustomer: boolean) => {
    setState((prev: ITireOrdersState) => ({ ...prev, loading: true }));
    try {
      const sortOrder = getSortOrder();
      const { allDates, dateFrom, dateTo, view, orderStatus, ...filters } = filtersState;
      const { data }: any = await apiService.tireOrders.get(
        { pageNumber: paginationProps.current, pageSize: +countOnPage.key },
        {
          ...filters,
          id: orderCode?.id,
          byCustomer: byCustomer,
          searchString: searchStr,
          dateFrom: allDates ? null : moment(dateFrom).format('MM/DD/YYYY'),
          dateTo: allDates ? null : moment(dateTo).format('MM/DD/YYYY'),
          view: view === 'ALL' ? null : view,
          orderStatus: orderStatus === 'ALL' ? null : orderStatus,
        },
        sortOrder,
        customerId,
        locationId,
      );
      byCustomer ? null : setState((prev: any) => ({ ...prev, selectedItem: data.data[0] }));
      dispatch(setInTransitOrderCode(null));
      if (get(data, 'desc.code', 200) !== 200) {
        addNotification({
          text: `Fetching orders error: ${get(data, 'desc.message', '')}`,
          type: MessageBarType.error,
        });
      } else {
        const foundCount = data.total.found;
        const items = data.data;
        setState((prev: any) => ({ ...prev, items, selectedItem: null, foundCount }));
        setPaginationProps((prev: any) => ({ ...prev, total: Math.ceil(foundCount / +countOnPage.key) }));
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching orders error: ${get(response, 'data.message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: ITireOrdersState) => ({ ...prev, loading: false }));
    }
  };

  const handleCancelOrder = async () => {
    toggleReturnConfirmation();
    setState((prev: ITireOrdersState) => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.tireOrders.cancelOrder(state.selectedItem.id, reasonOption, reasonString);
      if (get(data, 'desc.code', 200) !== 200) {
        addNotification({
          text: `Canceling order error: ${get(data, 'desc.message', '')}`,
          type: MessageBarType.error,
        });
      } else {
        addNotification({
          text: 'Order was successfully cancelled.',
          type: MessageBarType.success,
        });
      }
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Order canceling error: ${get(response, 'data.state[0].message', '')}`,
        type: MessageBarType.error,
      });
    } finally {
      fetch(filtersState.byCustomer);
      setReasonOption(null);
      setReasonString('');
      setShowReasonStringField(false);
    }
  };

  const handleDelete: any = async () => {
    toggleDeletingConfirmation();
    try {
      setState(prev => ({ ...prev, loading: true }));
      await apiService.tireOrders.removeOrder(state.selectedItem.id);
      addNotification({
        text: 'Selected order was successfully deleted.',
        type: MessageBarType.success,
      });
      await paginationProps.onChangePage(1);
      fetch(filtersState.byCustomer);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Order deleting error: ${get(response, 'data.state[0].message', '')}`,
        type: MessageBarType.error,
      });
      setState((prev: ITireOrdersState) => ({ ...prev, loading: false }));
    }
  };

  const handlePrint = async (printingType: any) => {
    setState(prev => ({ ...prev, loading: true }));
    toggleShowPrintExport();
    try {
      const sortOrder = getSortOrder();
      const { allDates, dateFrom, dateTo, view, orderStatus, ...filters } = filtersState;

      const requestData = {
        pagination: { pageSize: +countOnPage.key, pageNumber: paginationProps.current },
        filters: {
          ...filters,
          dateFrom: allDates ? null : moment(dateFrom).format('MM/DD/YYYY'),
          dateTo: allDates ? null : moment(dateTo).format('MM/DD/YYYY'),
          view: view === 'ALL' ? null : view,
          orderStatus: orderStatus === 'ALL' ? null : orderStatus,
        },
        sortOrder: sortOrder,
        customerId: customerId,
        locationId: locationId,
      };

      const headerFields = [
        { title: 'Customer Id', value: customerId },
        { title: 'Customer Name', value: customerName },
        { title: 'Location Id', value: isEmpty(locationId) ? 'N/A' : locationId },
        { title: 'Location Code', value: isEmpty(locationCode) ? 'N/A' : locationCode },
      ];

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.tireOrders.printExcelTireOrders(requestData, headerFields) :
        await apiService.tireOrders.printPdfTireOrders(requestData, headerFields);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Printing error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleSelect = (e: any, item: any) => {
    setState((prev: any) => ({ ...prev, selectedItem: e.target.checked ? item : null }));
  };

  const handleUpdateFilters = (updatedFilters: IFiltersState) => {
    toggleFilterVisible();
    setPaginationProps((prev: any) => ({ ...prev, current: 1 }));
    setFiltersState(updatedFilters);
  };

  const handleChangeSearchString = (event: any, searchString: string | undefined) => {
    setSearchString(searchString);
    setFiltersState((prev: IFiltersState) => ({ ...prev, searchString: searchStr }));
  };

  const openEditOrderModal = (order: any, mode: string) => {
    setCurrentOrder(order);
    setCurrentMode(mode);
    toggleEditOrderModal();
  };
  const handleReturn = () => {
    toggleReturnConfirmation();
    setReasonOption(null);
    setReasonString('');
    setShowReasonStringField(false);
  };

  const handleRedirect = (value: any) => {
    dispatch(setInTransitOrderCode(state.selectedItem));
    const { inTransitTires: { name } } = tireOrderTabs;
    dispatch(setActiveTab(name));
    dispatch(setActiveSubTab(value));
  };

  const onSubmitContinueDialog = () => {
    dispatch(setContractInformation({ ...contractInformation, submitted: true }));
    toggleContinueConfirmation();
  };

  const onCancelContinueDialog = () => {
    dispatch(clearRecordKeeping());
    dispatch(clearTireOrdering());
    toggleContinueConfirmation();
  };

  useEffect(() => {
    !isFirstLoading && !filterVisible ? fetch(filtersState.byCustomer) : null;
  }, [
    paginationProps.current,
    columnsState,
    countOnPage,
    locationId,
    filterVisible,
  ]);

  useEffect(() => {
    if (isFirstLoading) return;
    const timer = setTimeout(() => {
      fetch(filtersState.byCustomer);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchStr]);

  useEffect(() => {
    !isFirstLoading ? fetchContractInformation() : null;
  }, [customerId]);

  useEffect(() => {
    const byCustomer = orderCode ? false : true;
    setFiltersState((prev: any) => ({ ...prev, byCustomer }));
    !isFirstLoading ? fetch(byCustomer) : null;
  }, [orderCode, customerId, locationId]);

  useEffect(() => {
    const byCustomer = orderCode ? false : true;
    setFiltersState((prev: any) => ({ ...prev, byCustomer }));
    if (!isRkUsage)
      fetchContractInformation();
    fetch(byCustomer);
    wasFirstLoading();
  }, []);

  useEffect(() => {
    if (!contractInformation?.submitted && contractInformation?.activeStatus === 'NORMAL RUNOUT')
      toggleContinueConfirmation();
  }, [contractInformation]);

  return (
    <>
      {/* <div style={{ border: '1px solid #114375', marginTop: '16px' }}></div> */}
      <div className="ms-Grid">
        <div className={classNames('ms-Grid-row', styles.mainRow)}>
          <div className={classNames('ms-Grid-col', 'ms-sm12')}>
            <div className={styles.tabFiltersBlock}>
              <DefaultButton
                id="filterButton"
                className={classNames(styles.filterButton)}
                text="Filter"
                iconProps={filterIcon}
                onClick={() => toggleFilterVisible()}
              />
              <TextField
                id="searchString"
                label="Search"
                placeholder="Enter search string"
                onChange={handleChangeSearchString}
                disabled={orderCode}
              />
              <Dropdown
                id="dropdownITT"
                label="Action on order"
                placeholder="Select"
                options={inTransitTiresSubtab.filter((tab: any) => tab.isHidden === false)}
                disabled={isNil(state.selectedItem)}
                onChange={(e, selectedOption: any) => {
                  handleRedirect(selectedOption.key);
                }}
                styles={dropdownStyles}
              />
              {!isNil(contractInformation) && (
                <>
                  <TextField
                    value={moment(get(contractInformation, 'effectiveStartDate')).format('MM/DD/YYYY')}
                    label='Eff. Start Date'
                    disabled
                  />
                  <TextField
                    value={moment(get(contractInformation, 'currentEndDate')).format('MM/DD/YYYY')}
                    label='Eff. End Date'
                    disabled
                  />
                  <TextField
                    value={get(contractInformation, 'status')}
                    label='Contract Status'
                    disabled
                  />
                  <TextField
                    value={get(contractInformation, 'brandPrefix', '')}
                    label='Brand Prefix'
                    disabled
                  />
                </>
              )}
            </div>
            <div className={styles.tableHeading}>
              <div>
                <Text variant="xLarge" className={styles.highlight}>Available Tire Orders</Text>
                <SeparatorGy vertical />
                <Text variant="xLarge" className={styles.highlight}>{state.foundCount} found</Text>
              </div>
              <div>
                <Text variant="large">Show # of rows:&nbsp;</Text>
                <Dropdown
                  options={pageSizes.map(pageSize => ({
                    key: pageSize,
                    text: pageSize.toString(),
                  }))}
                  defaultSelectedKey={pageSizes[0]}
                  selectedKey={countOnPage?.key}
                  onChange={onChangeCountOnPage}
                />
                <SeparatorGy vertical />
                <IconButton
                  id="deleteIcon"
                  disabled={isNil(state.selectedItem) || !userPermissions.isWrite}
                  iconProps={{ iconName: 'Delete' }}
                  onClick={toggleDeletingConfirmation}
                />
              </div>
            </div>
            <div className={styles['table-wrapper']}>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    {
                      columnsState.map(item => (
                        <th key={item.name} className={item.isSorted && item.isSortedDescending ? styles.descending : item.isSorted && !item.isSortedDescending ? styles.ascending : undefined} onClick={() => onColumnClick(item)}>{item.name}</th>
                      ))
                    }
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.items.map((item: any) => {
                      const isItemSelected = state.selectedItem?.id === item.id;
                      return (
                        <tr key={item.id}
                          className={classNames(isItemSelected ? styles.trSelected : styles.trBasic)}
                        >
                          <td>
                            <IconButton
                              id="viewButton"
                              iconProps={{ iconName: 'View' }}
                              onClick={() => openEditOrderModal(item, 'view')}
                              disabled={!userPermissions.isRead}
                            />
                          </td>
                          <td>
                            <IconButton
                              id="viewButton"
                              iconProps={{ iconName: 'Edit' }}
                              onClick={() => openEditOrderModal(item, 'edit')}
                              disabled={!userPermissions.isWrite || item.status === 'FULFILLED' || item.status.includes('CANCEL')}
                            />
                          </td>
                          <td>{item.id}</td>
                          <td>{item.orderDate && moment(item.orderDate).format('MM/DD/YYYY')}</td>
                          <td>{item.status}</td>
                          <td>{item.typeCode}</td>
                          <td>{item.tc}</td>
                          <td>{item.beginBrand}</td>
                          <td>{item.endBrand}</td>
                          <td>{item.quantity}</td>
                          <td>{item.xferQuantity}</td>
                          <td>{item.shortQuantity}</td>
                          <td>{item.warhauseQuantity}</td>
                          <td>{item.dotQuantity}</td>
                          <td>{item.recvQuantity}</td>
                          <td>{item.createdBy}</td>
                          <td>{item.createdOn && moment(item.createdOn).format('MM/DD/YYYY')}</td>
                          <td>{item.lastModifiedBy}</td>
                          <td>{item.lastModifiedOn && moment(item.lastModifiedOn).format('MM/DD/YYYY')}</td>
                          <td>
                            <div className={styles.round}>
                              <input
                                type="checkbox"
                                id={item.id}
                                checked={isItemSelected}
                                onChange={(e) => handleSelect(e, item)}
                              />
                              <label htmlFor={item.id}></label>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <SeparatorGy />
            <Pagination {...paginationProps} />
          </div>
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className={classNames('ms-Grid-col', 'ms-sm12', styles.buttonsWrapper)}>
          <DefaultButton
            onClick={toggleReturnConfirmation}
            disabled={isNil(state.selectedItem) || !userPermissions.isWrite}
            text="Cancel Order"
          />
          <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
        </div>
      </div>
      <div className="dialogBoxTitle">
        <Dialog
          hidden={!isReturnDialogVisible}
          onDismiss={toggleReturnConfirmation}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Are you sure you would like to cancel this order?',
            subText: 'Please select the reason:',
          }}
          modalProps={{ isBlocking: true }}
          maxWidth={900}
        >
          <Dropdown
            id="cancelReasons"
            placeholder="Select Reason"
            options={cancelReasons}
            onChange={(e, selectedOption: any) => {
              selectedOption.key === OTHERS ? setShowReasonStringField(true) : setShowReasonStringField(false);
              setReasonOption(selectedOption.key);
              setReasonString('');
            }}
            styles={dropdownStyles}
          />
          {showReasonStringField &&
            <TextField
              id="othersReason"
              value={reasonString}
              label="Please specify reason"
              placeholder=""
              required={true}
              onChange={handleChangeOthersReason}
            />}
          <DialogFooter>
            <PrimaryButton id="returnDialogButton" onClick={handleCancelOrder} disabled={isNil(reasonOption) || (reasonOption === OTHERS && (isEmpty(reasonString.trim()) || isNil(reasonString.trim().length)))} text="Cancel Order(s)" />
            <DefaultButton onClick={handleReturn} text="No, return to tire orders" />
          </DialogFooter>
        </Dialog>
      </div>
      <Dialog
        hidden={!isDeletingDialogVisible}
        onDismiss={toggleDeletingConfirmation}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Confirmation',
          subText: 'CM112 - This will delete the item and all related data permanently from MIDAS. Do you wish to continue?',
        }}
        modalProps={{ isBlocking: true, topOffsetFixed: true }}
      >
        <DialogFooter>
          <PrimaryButton id="deleteDialogButton" onClick={handleDelete} text="Delete" />
          <DefaultButton onClick={toggleDeletingConfirmation} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <DialogComponent
        key='continueDialog'
        isOpen={isContinueDialogVisible}
        onCancel={onCancelContinueDialog}
        onSubmit={onSubmitContinueDialog}
        title='Confirmation'
        subText='CT565 - Do you want to continue? The Active Status of the selected contract is Normal Runout'
        onCancelLabel='No'
        onSubmitLabel='Yes'
      />
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <FilterModalWindow
        isOpened={filterVisible}
        filters={filtersState}
        onDismiss={toggleFilterVisible}
        setFilters={handleUpdateFilters}
      />
      <EditingModalWindow
        isOpened={isEditingOrderModalVisible}
        onDismiss={toggleEditOrderModal}
        order={currentOrder}
        mode={currentMode}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default TireOrders;
