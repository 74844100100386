import { sortOrder } from '../../../../consts/sortOrder';
import { HeadCell } from '../../../../shared/DataGridComponent/DataGridModels';

export const gbsPageSizes = [100, 250, 500];

export interface GBSErrorManagementProps {
  value?: string,
}

export interface IGBSError {
  logId?: string,
  messageId?: string,
  severity?: number,
  description?: string,
  addLPARM?: string,
  sourceOfError?: string,
  userId?: string,
  dateTime?: Date,
  resolution?: string,
  sapOrderNo?: string,
  gyDeliveryNoteNo?: string,
  customerNumber?: string,
  gbsOrderError?: string,
}

export interface IGBSOrder {
  orderId?: string,
  typeCode?: string,
  qty?: string,
  shipDate?: Date | undefined,
  productCode?: string,
  gdyrDelNo?: string,
  shipToNo?: string,
  billToNo?: string,
  cost?: number,
  gdyrOrderNo?: string,
  fstatus?: string,
}

export const initialGBSOrder: IGBSOrder = {
  orderId: '',
  typeCode: '',
  qty: '',
  shipDate: undefined,
  productCode: '',
  gdyrDelNo: '',
  shipToNo: '',
  billToNo: '',
  cost: 0,
  gdyrOrderNo: '',
  fstatus: '',
};

export const gbsErrorTableHeadCells: HeadCell[] = [
  {
    key: 'column0',
    typeField: 'text',
    isEditable: false,
    name: 'SAP Order Number',
    fieldName: 'sapOrderNo',
  },
  {
    key: 'column1',
    typeField: 'text',
    isEditable: false,
    name: 'Goodyear Delivery Note Number',
    fieldName: 'gyDeliveryNoteNo',
  },
  {
    key: 'column2',
    typeField: 'text',
    isEditable: false,
    name: 'Error Message',
    fieldName: 'gbsOrderError',
  },
];

export const GBS_ORDER_DETAIL_FIELDS = {
  midasOrderNumberDataField: {
    id: 'midasOrderNumber',
    label: 'GLS Order Number',
    name: 'midasOrderNumber',
    type: 'text',
    disabled: true,
  },
  customerNumberDataField: {
    id: 'customerNumber',
    label: 'Customer Number',
    name: 'customerNumber',
    type: 'text',
    disabled: false,
  },
  typeCodeDataField: {
    id: 'typeCode',
    label: 'Type Code',
    name: 'typeCode',
    type: 'text',
    disabled: false,
  },
  sapOrderNoDataField: {
    id: 'sapOrderNo',
    label: 'SAP Order #',
    name: 'sapOrderNo',
    type: 'text',
    disabled: false,
  },
  shipToNumberDataField: {
    id: 'shipToNumber',
    label: 'Ship To Number',
    name: 'shipToNumber',
    type: 'text',
    disabled: false,
  },
  productCodeDataField: {
    id: 'productCode',
    label: 'Product Code',
    name: 'productCode',
    type: 'text',
    disabled: false,
  },
  goodyearDeliveryNoteNumberDataField: {
    id: 'goodyearDeliveryNoteNumber',
    label: 'Goodyear Delivery Note Number',
    name: 'goodyearDeliveryNoteNumber',
    type: 'text',
    disabled: false,
  },
  quantityDataField: {
    id: 'quantity',
    label: 'Quantity',
    name: 'quantity',
    type: 'text',
    disabled: false,
  },
  costDataField: {
    id: 'cost',
    label: 'Cost',
    name: 'cost',
    type: 'text',
    disabled: false,
  },
  shipDateDataField: {
    id: 'shipDate',
    label: 'Ship Date',
    name: 'shipDate',
    type: 'date',
    disabled: false,
    required: false,
  },
  statusDataField: {
    id: 'status',
    label: 'Status',
    name: 'status',
    type: 'text',
    disabled: true,
  },
  GBSErrorDateDataField: {
    id: 'gbsDate',
    label: 'Date',
    name: 'date',
    type: 'date',
    disabled: false,
  },
};

export const defaultSorting = { column: gbsErrorTableHeadCells[0].fieldName, order: sortOrder.ASC };