import React, { FC, ReactElement, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
  IconButton,
  IIconProps,
  Text,
  Dropdown,
  DefaultButton,
  DatePicker,
  IDropdownOption,
  Toggle,
  MessageBarType,
} from '@fluentui/react';

import apiService from '../../../../../../../api';
import useNotifications from '../../../../../../../hooks/useNotifications';
import { customerInformationSelector, customerSelector, locationSelector } from '../../../../../../../redux/recordKeepingSlice';

import LoadingScreen from '../../../../../../LoadingScreen/LoadingScreen';

import { emptyFilters } from '../consts';

import {
  IFilterModalWindowProps,
} from './FilterModalWindowProps';
import styles from './FilterModalWindow.module.scss';
import moment from 'moment';

const FilterModalWindow: FC<IFilterModalWindowProps> = ({
  isOpened,
  filters,
  setFilters,
}): ReactElement => {
  const { id: customerId } = useSelector(customerSelector);
  const { id: locationId } = useSelector(locationSelector);

  const { addNotification } = useNotifications();

  const [filtersState, setFiltersState] = useState(filters);
  const [state, setState] = useState<any>({
    loading: false,
    models: [],
    makes: [],
    wheels: [],
    statuses: [],
  });
  const { billBy } = useSelector(customerInformationSelector);


  const closeIcon: IIconProps = { iconName: 'Cancel' };

  const applyFilters = () => {
    setFilters({ ...filtersState });
    isOpened(false);
  };

  const clearFilters = () => {
    setFiltersState({ ...emptyFilters });
  };

  const containerRef = useRef(null);

  const handleClickOutside = (event: any) => {
    if (!(containerRef.current as any).contains(event.target)) {
      isOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [containerRef]);

  useEffect(() => {
    getMake();
    getWheels();
    getStatus();
  }, [customerId]);

  const getMake = async () => {
    setState((prev: any) => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.registerVehicles.getMake(
        customerId,
      );
      setState((prev: any) => ({ ...prev, makes: data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching filters error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const getWheels = async () => {
    setState((prev: any) => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.registerVehicles.getWheels(
        customerId,
        billBy == 'Location' ? locationId : null,
      );
      setState((prev: any) => ({ ...prev, wheels: data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching filters error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const getStatus = async () => {
    setState((prev: any) => ({ ...prev, loading: true }));
    try {
      const { data } = await apiService.registerVehicles.getStatus(
        customerId,
        billBy == 'Location' ? locationId : null,
      );
      setState((prev: any) => ({ ...prev, statuses: data }));
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Fetching filters error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleChangeWhlPos = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setFiltersState((prevState: any) => ({ ...prevState, whlPos: item?.key }));
  };

  const handleChangeStatus = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined): void => {
    setFiltersState((prevState: any) => ({ ...prevState, status: item?.key }));
  };

  const handleShowUnregisteredChange = (event: any, showUnregistered: boolean | undefined): void => {
    setFiltersState((prevState: any) => ({ ...prevState, showUnregistered }));
  };

  const handleChangeDateFrom = (changeDateFrom: Date | null | undefined): void => {
    setFiltersState((prevState: any) => ({ ...prevState, changeDateFrom }));
  };

  const handleChangeDateTo = (changeDateTo: Date | null | undefined): void => {
    setFiltersState((prevState: any) => ({ ...prevState, changeDateTo }));
  };

  const handleChangeAsOfFrom = (asOfFrom: Date | null | undefined): void => {
    setFiltersState((prevState: any) => ({ ...prevState, asOfFrom }));
  };

  const handleChangeAsOfTo = (asOfTo: Date | null | undefined): void => {
    setFiltersState((prevState: any) => ({ ...prevState, asOfTo }));
  };

  const handleChangeMake = (clickedMake: any): void => {
    setFiltersState((prevState: any) => {
      if (prevState.make.indexOf(clickedMake) === -1)
        return { ...prevState, make: [...prevState.make, clickedMake] };
      return { ...prevState, make: prevState.make.filter((make: any) => make !== clickedMake) };
    });
  };

  return <div>

    <div id="filterModalWindow" className={classNames(styles.filterWrapper)}>
      <div className={classNames(styles.filterBackground)}>
        <div className={classNames(styles.filterContainer)} ref={containerRef}>
          <div className={classNames(styles.filterHeader)}>
            <Text variant="xxLarge">Filter</Text>
            <IconButton
              id="closeButton"
              iconProps={closeIcon}
              title="close"
              onClick={() => isOpened(false)}
            />
          </div>
          <div className={classNames(styles.filterBody)}>
            <div className={classNames(styles.row)}>
              <div className={classNames(styles.column)}>
                <label htmlFor="show-unregistered">
                  <Text variant="xLarge">Include Unregistered</Text>
                </label>
                <Toggle id="show-unregistered" checked={filtersState.showUnregistered} onChange={handleShowUnregisteredChange} />
              </div>
            </div>
            <div className={classNames(styles.row)}>
              <div className={classNames(styles.column)}>
                <label>
                  <Text variant="xLarge"># Wheel Positions</Text>
                </label>
                <Dropdown
                  id="wheelPositions"
                  placeholder="Select Wheel Position"
                  options={state.wheels.map((wheel: any) => ({ key: wheel, text: wheel }))}
                  selectedKey={filtersState.whlPos}
                  onChange={handleChangeWhlPos}
                />
              </div>
              <div
                className={classNames(
                  styles.column,
                  styles.calendarContainer,
                )}
              >
                <label>
                  <Text variant="xLarge">Year</Text>
                </label>
                <div>
                  <label>
                    <Text variant="large">From</Text>
                  </label>
                  <DatePicker
                    id="changeDateFrom"
                    showMonthPickerAsOverlay={true}
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={filtersState.changeDateFrom}
                    onSelectDate={handleChangeDateFrom}
                    formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                  />
                </div>
                <div>
                  <label>
                    <Text variant="large">To</Text>
                  </label>
                  <DatePicker
                    id="changeDateTo"
                    showMonthPickerAsOverlay={true}
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={filtersState.changeDateTo}
                    onSelectDate={handleChangeDateTo}
                    formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                  />
                </div>
              </div>
            </div>
            <div className={classNames(styles.row)}>
              <div className={classNames(styles.column)}>
                <Text variant="xLarge" block>Make</Text>
                {
                  state.makes.map((make: any) => {
                    const doesMakesContainThis = filtersState.make.indexOf(make) !== -1;

                    return (
                      <DefaultButton
                        toggle
                        className={classNames(styles.checkboxButton, { [styles.checkedButton]: doesMakesContainThis })}
                        checked={doesMakesContainThis}
                        text={make}
                        onClick={() => handleChangeMake(make)}
                      />
                    );
                  })
                }
              </div>
              <div className={classNames(styles.column)}>
                <label>
                  <Text variant="xLarge">Status</Text>
                </label>
                <Dropdown
                  id="status"
                  placeholder="Select Status"
                  options={state.statuses.map((status: any) => ({ key: status, text: status }))}
                  selectedKey={filtersState.status}
                  onChange={handleChangeStatus}
                />
              </div>
            </div>
            <div className={classNames(styles.row)}>
              <div
                className={classNames(
                  styles.column,
                  styles.calendarContainer,
                )}
              >
                <label>
                  <Text variant="xLarge">As Of</Text>
                </label>
                <div>
                  <label>
                    <Text variant="large">From</Text>
                  </label>
                  <DatePicker
                    id="asOfFrom"
                    showMonthPickerAsOverlay={true}
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={filtersState.asOfFrom}
                    onSelectDate={handleChangeAsOfFrom}
                    formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                  />
                </div>
                <div>
                  <label>
                    <Text variant="large">To</Text>
                  </label>
                  <DatePicker
                    id="asOfTo"
                    showMonthPickerAsOverlay={true}
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={filtersState.asOfTo}
                    onSelectDate={handleChangeAsOfTo}
                    formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={classNames(styles.filterFooter)}>
            <DefaultButton
              id="clearButton"
              onClick={clearFilters}
              text="Clear Filters"
            />
            <DefaultButton
              id="applyButton"
              onClick={applyFilters}
              disabled={
                (filtersState.asOfFrom && filtersState.asOfTo && (moment(filtersState.asOfTo).isBefore(filtersState.asOfFrom))) ||
                (filtersState.changeDateFrom && filtersState.changeDateTo && (moment(filtersState.changeDateTo).isBefore(filtersState.changeDateFrom)))
              }
              text="Apply"
            />
          </div>
        </div>
      </div>
    </div>
    {state.loading && <LoadingScreen />}
  </div>;
};

export default FilterModalWindow;

