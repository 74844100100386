import { ChoiceGroup, IChoiceGroupOption, IconButton, Text, TextField } from '@fluentui/react';
import ShipToOemModalComponent from '../../../../../../../../shared/ShipToOemModalComponent';
import { gdyrIdEMHandler } from '../../../../../../../../shared/TextFieldValidation';
import { EnteredManuallySentToR3Options } from './consts';
import { useBoolean } from '@fluentui/react-hooks';
import { IOrderHeaderProps } from './IOrderHeaderProps';
import { FC } from 'react';
import styles from './OrderHeader.module.scss';

const OrderHeader: FC<IOrderHeaderProps> = ({
  shipToOem,
  setShipToOem,
  orderToBeAdded, 
  setOrderToBeAdded, 
}) => {
  const [showShipToOemModal, { toggle: toggleShowShipToOemModal }] = useBoolean(false);

  const onChangeOrderField = (field: any, value: any, regExp?: RegExp) => {
    setOrderToBeAdded((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));
  };

  const setEnteredManuallySentToR3Options = (value: any) => {
    value === 'enteredManually' ? 
      setOrderToBeAdded((prev: any) => ({ ...prev, enteredManually: true, sentToR3: false })) : 
      setOrderToBeAdded((prev: any) => ({ ...prev, enteredManually: false, sentToR3: true }));
  };

  const handleSelectShipTo = (rowSelected: any) => {
    setOrderToBeAdded((prev: any) => ({ ...prev, shipToOem: rowSelected[0].id }));
    setShipToOem(rowSelected[0].name);
    toggleShowShipToOemModal();
  };

  return (
    <>
      <div className={styles.container}>
        <Text variant="xLarge" className={styles.highlight}>Order Header</Text>
        <div>
          <div>
            <TextField
              label="GLS Order #"
              value={orderToBeAdded.id}
              disabled
            />
          </div>
          <div>
            <TextField
              label="SAP Order #"
              maxLength={10}
              value={orderToBeAdded.gdyrId}
              onChange={(e, gdyrId: any) => onChangeOrderField('gdyrId', gdyrId, /^[0-9]*$/)}
              onGetErrorMessage={gdyrIdEMHandler}
              required={orderToBeAdded.enteredManually}
            />
          </div>
        </div>
        <div>
          <div className={styles.choiseGroup}>
            <ChoiceGroup
              selectedKey={orderToBeAdded.sentToR3 ? 'sentToR3' : 'enteredManually'}
              options={EnteredManuallySentToR3Options}
              onChange={(ev: any, option: IChoiceGroupOption | undefined) => setEnteredManuallySentToR3Options((option?.key))}
            />
          </div>
        </div>
        <div>
          <div>
            <TextField  
              id="shipToOem"
              label="ShipTo OEM"
              value={shipToOem}
              disabled
            />
            <IconButton
              id='searchPrefix'
              onClick={toggleShowShipToOemModal}
              iconProps={{ iconName: 'Search' }}
            />
          </div>
        </div>
      </div>
      <ShipToOemModalComponent
        id='shipToModal'
        title='Ship To OEMs'
        isOpen={showShipToOemModal}
        onSubmit={handleSelectShipTo}
        onDismiss={toggleShowShipToOemModal}
        successLabel='Select Ship To'
        cancelLabel='Cancel'
      />
    </>
  );
};

export default OrderHeader;