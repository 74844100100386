import { FC, ReactElement } from 'react';
import { IGeneralDetailsTabProps } from './GeneralDetailsTabProps';
import styles from './GeneralDetailsTab.module.scss';

import { Text} from '@fluentui/react';
import classNames from 'classnames';
import { get } from 'lodash';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import moment from 'moment';

const GeneralDetailsTab: FC<IGeneralDetailsTabProps> = ({details}): ReactElement => {

  return (
    <div className={classNames(styles.container)}>
      <div className={classNames(styles.infoBlocks)}>
        <div className="infoBlock">
          <Text variant="xLarge" className={styles.highlight}>Tire Details:</Text>
          <div className={classNames(styles.infoBlockData)}>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Tire Class:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'tireDetails.tireClass', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Type Code:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'tireDetails.typeCode', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Product Code:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'tireDetails.productCode', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Tire Size:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'tireDetails.tireSize', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Description:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'tireDetails.description', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>DOT #:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'tireDetails.dotNo', 'N/A')}</Text>
            </div>
            <SeparatorGy alignContent="end" />
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Total Miles:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'tireDetails.totalMiles', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>As Of:</Text>
              <Text variant="large" className={styles.highlight}>{details?.tireDetails?.asOf ? moment(details.tireDetails.asOf).format('MM/DD/YYYY') : 'N/A'}</Text>
            </div>
          </div>
        </div>
        <div className="infoBlock">
          <Text variant="xLarge" className={styles.highlight}>Current Position</Text>
          <div className={classNames(styles.infoBlockData)}>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Vehicle:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'currentPosition.vehicleNo', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Position:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'currentPosition.position', 'N/A')}</Text>
            </div>

            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>On Date:</Text>
              <Text variant="large" className={styles.highlight}>{details?.currentPosition?.onDate ? moment(details.currentPosition.onDate).format('MM/DD/YYYY') : 'N/A'}</Text>
            </div>

            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Location Code:</Text>
              <Text variant="large" className={styles.highlight}>
                {get(details, 'currentPosition.locationCode', 'N/A')}
              </Text>
            </div>
          </div>
        </div>
        <div className="infoBlock">
          <Text variant="xLarge" className={styles.highlight}>Flagged as Damaged</Text>
          <div className={classNames(styles.infoBlockData)}>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>A:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'damaged.a', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>B:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'damaged.b', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>FAD Date:</Text>
              <Text variant="large" className={styles.highlight}>{details?.damaged?.fadDate ? moment(details.damaged.fadDate).format('MM/DD/YYYY') : 'N/A'}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>FAS Doc:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'damaged.fadDoc', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Flagged As Dmg:</Text>
              <Text variant="large" className={styles.highlight}>{details && details.damaged.flaggedAsDmg ? 'Yes' : details && !details.damaged.flaggedAsDmg ? 'No' : 'N/A'}</Text>
            </div>
            <SeparatorGy alignContent="end" />
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}> Marked as Lost:</Text>
              <Text variant="large" className={styles.highlight}>{details && details.damaged.markedAsLost ? 'Y' : details && !details.damaged.markedAsLost ? 'N' : 'N/A'}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Annual:</Text>
              <Text variant="large" className={styles.highlight}>{details?.damaged?.annual ?  moment(details.damaged.annual).format('MM/DD/YYYY') : 'N/A'}</Text>
            </div>
          </div>
        </div>
        <div className="infoBlock">
          <Text variant="xLarge" className={styles.highlight}>OOS Details:</Text>
          <div className={classNames(styles.infoBlockData)}>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>A:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'oosDetails.a', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>B:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'oosDetails.b', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Disp:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'oosDetails.disp', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Doc:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'oosDetails.doc', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Doc Date:</Text>
              <Text variant="large" className={styles.highlight}>{details?.oosDetails?.docDate ? moment(details.oosDetails.docDate).format('MM/DD/YYYY') : 'N/A'}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>OOS Date:</Text>
              <Text variant="large" className={styles.highlight}>{details?.oosDetails?.oosDate ? moment(details.oosDetails.oosDate).format('MM/DD/YYYY') : 'N/A'}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Over Average:</Text>
              <Text variant="large" className={styles.highlight}>{details && details.oosDetails.overAverage ? 'Yes' : details && !details.oosDetails.overAverage ? 'No' : 'N/A'}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>RG:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'oosDetails.rg', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Status:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'oosDetails.status', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Tread:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'oosDetails.tread', 'N/A')}</Text>
            </div>
            <SeparatorGy alignContent="end" />
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Retreaded:</Text>
              <Text variant="large" className={styles.highlight}>{get(details, 'oosDetails.retreaded', 'N/A')}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Include In OOS Avg:</Text>
              <Text variant="large" className={styles.highlight}>{details?.oosDetails.includeInAvg ? 'Yes' : !details?.oosDetails.includeInAvg ? 'No' : 'N/A'}</Text>
            </div>
            <div className={classNames(styles.row)}>
              <Text variant="large" className={styles.highlight}>Damage Billable:</Text>
              <Text variant="large" className={styles.highlight}>{details?.oosDetails.damageBillable ? 'Yes' : !details?.oosDetails.damageBillable ? 'No' : 'N/A'}</Text>
            </div>
          </div>
        </div>
      </div>
    </div> 
  );
};

export default GeneralDetailsTab;
