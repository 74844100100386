import { FunctionComponent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { DefaultButton, MessageBarType, PrimaryButton, Text, TextField, IconButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil } from 'lodash';

import apiService from '../../../../../../api';
import useNotifications from '../../../../../../hooks/useNotifications';
import DialogComponent from '../../../../../../shared/DialogComponent';
import DataGridComponent from '../../../../../../shared/DataGridComponent';
import { inTransitOrderCodeSelector, setInTransitOrderCode } from '../../../../../../redux/tireOrderingSlice';
import { IAvailableTires } from '../../../../../../models/IAvailableTires';
import { INotification } from '../../../../../../models/INotification';
import { useField } from '../../../../../../hooks/useField';
import { TireStatusesEnum } from '../../../../../../models/TireStatusesEnum';
import { availableLocationsSelector, customerSelector } from '../../../../../../redux/recordKeepingSlice';
import { TemporaryTireStatusesEnum } from '../../../../../../models/TemporaryTireStatusesEnum';

import PrintingModal from '../../../../../PrintingModal/PrintingModal';
import SeparatorGy from '../../../../../SeparatorGy/SeparatorGy';

import { IUpdateWithTRRProps } from './IUpdateWithTRRProps';
import { RECEIVING_RECORD_FIELDS, availableTiresColumns, updatedConsignedTiresColumns, SERIES_FIELDS } from './consts';

import styles from './UpdateWithTRR.module.scss';
import { ITemporaryTires } from '../../../../../../models/ITemporaryTires';
import { downloadFile, printingTypes } from '../../../../../PrintingModal/consts';
import { useUserPermissions } from '../../../../../../hooks/useUserPermissions';
import { auth_inTransitTires_updateWithTRR } from '../../../../../../consts/programKeys';
import { invoiceDelivNoteEMHandler } from '../../../../../../shared/TextFieldValidation';
import { intransitTRRPageSizes } from './consts';
import DelNumComponent from '../../../../../../shared/DelNumComponent';
import LocationsModalComponent from '../../../../../../shared/LocationsModalComponent';


const UpdateWithTRR: FunctionComponent<IUpdateWithTRRProps> = () => {

  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_inTransitTires_updateWithTRR);

  const dispatch = useDispatch();

  const { prefix: customerPrefix, customerName, regionCode, id: customerId } = useSelector(customerSelector);
  const locationsList = useSelector(availableLocationsSelector);
  const orderCode = useSelector(inTransitOrderCodeSelector);

  const { addNotification } = useNotifications();

  const [paginationPropsAvailable, setPaginationPropsAvailable] = useState<any>();
  const [paginationPropsUpdated, setPaginationPropsUpdated] = useState<any>();
  const [availableTiresList, setAvailableTiresList] = useState([]);
  const [tiresToUpdateList, setTiresToUpdateList] = useState<Array<ITemporaryTires>>([]);
  const [totalFound, setTotalFound] = useState(0);
  const [totalFoundUpdate, setTotalFoundUpdate] = useState(0);
  const [availableTiresSelected, setAvailableTiresSelected] = useState<Array<IAvailableTires>>([]);
  const [toUpdateTiresSelected, setToUpdateTiresSelected] = useState<Array<ITemporaryTires>>([]);
  const [notification, setNotification] = useState<INotification>({ text: '', type: 0 });
  const [availableTiresSeriesSelected, setAvailableTiresSeriesSelected] = useState<Array<IAvailableTires>>([]);

  const [isLoadingAvailableTable, { toggle: toggleShowLoadingAvailableTable }] = useBoolean(false);
  const [isLoadingTemporaryTable, { toggle: toggleShowLoadingTemporaryTable }] = useBoolean(false);
  const [reloadControlsTable, { toggle: toggleReloadControlsTable }] = useBoolean(false);
  const [showPrintExport, { toggle: toggleShowPrintExport }] = useBoolean(false);
  const [isUpdatingDialogVisible, { toggle: toggleUpdatingConfirmation }] = useBoolean(false);
  const [runningStatus, setRunningStatus] = useState<string>('IDLE');

  const [isLocationModalOpen, { toggle: toggleLocationModal }] = useBoolean(false);

  const trrDocNumField = useField(RECEIVING_RECORD_FIELDS.trrDocNumDataField);
  const receivedDateField = useField(RECEIVING_RECORD_FIELDS.receivedDateField);

  const beginPrefixField = useField(SERIES_FIELDS.beginPrefixDataField);
  const beginBrandField = useField(SERIES_FIELDS.beginBrandDataField);
  const beginSufixField = useField(SERIES_FIELDS.beginSufixDataField);
  const endPrefixField = useField(SERIES_FIELDS.endPrefixDataField);
  const endBrandField = useField(SERIES_FIELDS.endBrandDataField);
  const endSufixField = useField(SERIES_FIELDS.endSufixDataField);

  const fetchData = async (
    pagination: any = { pageSize: intransitTRRPageSizes[0], pageNumber: 1 },
    filters: any = { status: TireStatusesEnum.INTRANSIT },
    sortOrder: any = { column: availableTiresColumns[0].fieldName, order: 'asc' },
    shouldAutoSelectRows = false,
  ) => {
    try {
      if (!shouldAutoSelectRows) {
        setAvailableTiresList([]);
        setAvailableTiresSelected([]);
        setAvailableTiresSeriesSelected([]);
        setTotalFound(0);
      }
      filters = {
        ...filters,
        customerId,
        orderId: orderCode?.id,
      };
      const { data }: any = await apiService.inTransitTiresAPI.getAvailableInTransitTires(pagination, filters, sortOrder);
      if (shouldAutoSelectRows) {
        setAvailableTiresSeriesSelected(data?.data);
      }
      else {
        setAvailableTiresList(data?.data);
        setTotalFound(data?.total?.found);
      }
    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const fetchUpdatedData = async (
    pagination: any = { pageSize: intransitTRRPageSizes[0], pageNumber: 1 },
    filters: any = { status: TemporaryTireStatusesEnum.PENDING_UPDATEWITHTRR, customerId },
    sortOrder?: any,
  ) => {
    try {
      const { data }: any = await apiService.inTransitTiresAPI.getTemporaryTires(pagination, filters, sortOrder);
      setTiresToUpdateList(data?.data);
      setTotalFoundUpdate(data?.total?.found);
    } catch (error) {
      setNotification({ text: 'Fetching tires error', type: MessageBarType.error });
    }
  };

  const updateTRR = async () => {
    try {
      toggleUpdatingConfirmation();
      toggleShowLoadingTemporaryTable();
      const inTransitTireIds = getIdsFromTires(toUpdateTiresSelected);
      if (inTransitTireIds.length > 200) {
        const { data: { message } }: any = await apiService.inTransitTiresAPI.updateTRRQueue({ orderId: orderCode?.id, ids: inTransitTireIds });
        await fetchStatus();
        if (runningStatus == 'IDLE') {
          setNotification({ text: message, type: MessageBarType.success });
        }
      }
      else {
        await apiService.inTransitTiresAPI.updateTRR({ orderId: orderCode?.id, ids: inTransitTireIds });
        setNotification({ text: `${inTransitTireIds.length} tire(s) updated successfully`, type: MessageBarType.success });
      }
      toggleReloadControlsTable();
      trrDocNumField.setValue('');
      receivedDateField.setValue('');
      await fetchUpdatedData();
    } catch (e: any) {
      const { response } = e;
      switch (response.status) {
        case 499:
          if (response.data.state.length) {
            response.data.state.forEach(({ message }: any) => {
              addNotification({
                text: message,
                type: MessageBarType.error,
              });
            });
          } else {
            addNotification({
              text: 'Updating tires error',
              type: MessageBarType.error,
            });
          }
          break;
        default:
          addNotification({
            text: 'Updating tires error',
            type: MessageBarType.error,
          });
      }
    } finally {
      toggleShowLoadingTemporaryTable();
    }
  };

  const fetchStatus = async () => {
    let timeOut = null;
    try {
      const inTransitTireIds = getIdsFromTires(toUpdateTiresSelected);
      const { data: { runStatus } }: any = await apiService.inTransitTiresAPI.updateWithTRRStatus({ orderId: orderCode?.id, ids: inTransitTireIds });

      if (timeOut) clearInterval(timeOut);
      if (runStatus == 'RUNNING') timeOut = setTimeout(() => fetchStatus(), 10000);
      else if (runStatus == 'COMPLETED') {
        setNotification({ text: `${inTransitTireIds.length} tire(s) updated successfully`, type: MessageBarType.success });
      }
      setRunningStatus(runStatus);
    } catch (e: any) {
      addNotification({ text: 'Error fetching status', type: MessageBarType.error });
    }
    finally {
      await fetchUpdatedData();
      toggleShowLoadingTemporaryTable();
    }
  };

  const handleChangeSeries = async () => {
    toggleShowLoadingAvailableTable();
    const inTransitTiresSelectRange = getRangeObject();
    await fetchData(null, {
      inTransitTiresSelectRange,
      status: TireStatusesEnum.INTRANSIT,
    }, null, true);
    toggleShowLoadingAvailableTable();
  };

  const handleChangeDataGridState = async (dataGridState: any) => {
    toggleShowLoadingAvailableTable();

    const { countOnPage, paginationProps, searchedText, sortOrder } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
      status: TireStatusesEnum.INTRANSIT,
      orderId: orderCode?.id,
      inTransitTiresSelectRange: getRangeObject(),
    };
    setPaginationPropsAvailable(dataGridState);
    if (orderCode) await fetchData(pagination, filters, sortOrder);
    toggleShowLoadingAvailableTable();
  };

  const handleChangeUpdatedGridState = async (dataGridState: any) => {
    toggleShowLoadingTemporaryTable();
    const { countOnPage, paginationProps, sortOrder, searchedText } = dataGridState;
    const pagination = {
      pageSize: countOnPage.key,
      pageNumber: paginationProps.current,
    };
    const filters = {
      searchString: searchedText,
      status: TemporaryTireStatusesEnum.PENDING_UPDATEWITHTRR,
      customerId,
    };
    setPaginationPropsUpdated(dataGridState);
    if (orderCode) await fetchUpdatedData(pagination, filters, sortOrder);
    toggleShowLoadingTemporaryTable();
  };

  const handleSelectAvailableRow = (rowsSelected: Array<IAvailableTires>) => {
    setAvailableTiresSelected(rowsSelected);
  };
  const handleSelectToUpdateRow = (rowsSelected: Array<ITemporaryTires>) => {
    setToUpdateTiresSelected(rowsSelected);
  };

  const handleOnLocationSubmit = (location: any) => {
    toggleLocationModal();
    dispatch(setInTransitOrderCode(({...orderCode, locationId: location?.id})));
  };

  const getLocationById = (idToFind: string) => {
    const locationToFind = locationsList?.find(({ id }: any) => id === idToFind);
    return locationToFind ? locationToFind : null;
  };
  
  const getRangeObject = () => {
    return !disableSelectSeriesBtn ?
      {
        beginBrand: {
          prefix: beginPrefixField.getValue(),
          brandNo: beginBrandField.getValue(),
          sufix: beginSufixField.getValue(),
        },
        endBrand: {
          prefix: beginPrefixField.getValue(),
          brandNo: endBrandField.getValue(),
          sufix: beginSufixField.getValue(),
        },
      } : null;
  };

  const transferTiresToAvailable = async () => {
    try {
      toggleShowLoadingAvailableTable();
      toggleShowLoadingTemporaryTable();
      await apiService.inTransitTiresAPI.moveTiresBackToMainTable(toUpdateTiresSelected?.map((tire: any) => tire.inTransitTireId || tire.inTransitTireAltId));
      setNotification({
        text: 'Successfully moved back to available',
        type: MessageBarType.success,
      });
      setToUpdateTiresSelected([]);

      await fetchUpdatedData();
      await fetchData();
    } catch (error) {
      setNotification({ text: 'Moving back to available error', type: MessageBarType.error });
    } finally {
      toggleShowLoadingAvailableTable();
      toggleShowLoadingTemporaryTable();
    }
  };

  const transferTiresToUpdate = async () => {
    try {
      toggleShowLoadingAvailableTable();
      toggleShowLoadingTemporaryTable();
      await apiService.inTransitTiresAPI.moveTiresToTemporary({
        status: TemporaryTireStatusesEnum.PENDING_UPDATEWITHTRR,
        ids: availableTiresSelected.map(({ inTransitTireId }: any) => inTransitTireId),
        trrDoc: trrDocNumField.value,
        recvDt: (new Date(receivedDateField.value)).toISOString(),
        locationId: orderCode?.locationId,
      });
      setNotification({
        text: 'Successfully moved to In-transit',
        type: MessageBarType.success,
      });

      setAvailableTiresSelected([]);
      setToUpdateTiresSelected([]);
      clearSelectionInputs();

      await fetchData();
      await fetchUpdatedData();
    } catch (error) {
      setNotification({ text: 'Moving to In-transit tires error', type: MessageBarType.error });
    } finally {
      toggleShowLoadingAvailableTable();
      toggleShowLoadingTemporaryTable();
    }
  };

  const handlePrint = async (printingType: any) => {
    try {

      const inTransitTiresGetInput = {
        pagination: {
          pageSize: paginationPropsAvailable?.countOnPage?.text,
          pageNumber: paginationPropsAvailable?.paginationProps?.current,
        },
        filters: {
          customerId: customerId,
          locationId: regionCode,
        },
      };

      const inTransitTiresAltGetInput = {
        pagination: {
          pageSize: paginationPropsUpdated?.countOnPage?.text,
          pageNumber: paginationPropsUpdated?.paginationProps?.current,
        },
        filters: {
          customerId: customerId,
          locationId: regionCode,
        },
      };

      const headerFields = [
        { title: 'customerId', value: customerId },
        { title: 'locationId', value: regionCode },
      ];

      const requestData = {
        inTransitTiresGetInput: inTransitTiresGetInput,
        inTransitTiresAltGetInput: inTransitTiresAltGetInput,
        headerFields: headerFields,
      };

      const { data }: any = printingType === printingTypes.excel ?
        await apiService.inTransitTiresAPI.printExcel(requestData) :
        await apiService.inTransitTiresAPI.printPdf(requestData);

      addNotification({
        text: 'File was successfully received.',
        type: MessageBarType.success,
      });
      downloadFile(data, printingType);
      toggleShowPrintExport();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: 'Printing error',
        type: MessageBarType.error,
      });
    }
  };

  const clearSelectionInputs = () => {
    beginBrandField.setValue('');
    beginPrefixField.setValue('');
    beginSufixField.setValue('');
    endBrandField.setValue('');
    endPrefixField.setValue('');
    endSufixField.setValue('');
  };

  const getIdsFromTires = (tiresArray: any) => tiresArray?.map((tire: any) => tire.inTransitTireId || tire.inTransitTireAltId);
  const getPrefixValue = beginPrefixField.getValue();
  const getSufixValue = beginSufixField.getValue();
  const disableToUpdateButton = availableTiresSelected.length == 0 
  || isNil(trrDocNumField.value) 
  || isEmpty(trrDocNumField.value) 
  || isNil(receivedDateField.value) 
  || isEmpty(receivedDateField.value)
  || isEmpty(orderCode?.locationId);
  const disableToAvailableAButton = toUpdateTiresSelected.length == 0;
  const disableSelectSeriesBtn = !beginPrefixField.getValue();
  const disableUpdateBtn = tiresToUpdateList.length == 0 || isNil(trrDocNumField.value) || isEmpty(trrDocNumField.value) || isNil(receivedDateField.value) || isEmpty(receivedDateField.value);

  useEffect(() => {
    if (notification?.text) {
      addNotification({ ...notification });
      setNotification({ text: '', type: 0 });
    }
  }, [notification]);

  return (
    <>
      <div className='ms-grid margin-left-rigth-2'>
        <div className='ms-Grid-row'>
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            <Text variant='xLarge' className={styles.highlight}>Order Details</Text>
            <SeparatorGy />
            <div className='ms-Grid'>
              <DelNumComponent orderCode={orderCode} />
            </div>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm6')}>
            <Text variant='xLarge' className={styles.highlight}>Receiving Record</Text>
            <SeparatorGy />
            <div className='ms-Grid'>
              <div className='ms-Grid-row'>
                <div className="ms-Grid-col">
                  <TextField
                    id="brandPrefix"
                    label="Brand Prefix"
                    name="brandPrefix"
                    type="text"
                    value={customerPrefix}
                    disabled
                  />
                </div>
                <div className="ms-Grid-col">
                  <TextField
                    id="name"
                    label="Name"
                    name="name"
                    type="text"
                    value={customerName}
                    disabled
                  />
                </div>
                <div className={classNames('ms-Grid-col', 'inputGroup', 'inputWithButton')}>
                  <TextField
                    id="locationCode"
                    label="Location Code"
                    name="locationCode"
                    type="text"
                    value={getLocationById(orderCode?.locationId)?.locationCode}
                    disabled
                    required
                  />
                  {orderCode?.shipToOem && !orderCode?.locationId &&
                  <IconButton
                    className={styles.searchLocationIcon}
                    onClick={toggleLocationModal}
                    iconProps={{ iconName: 'Search' }}
                  /> }
                </div>
                <div className="ms-Grid-col">
                  <TextField {...trrDocNumField} onGetErrorMessage={invoiceDelivNoteEMHandler} />
                </div>
                <div className="ms-Grid-col">
                  <TextField {...receivedDateField} />
                </div>
              </div>
            </div>
          </div>
          {(availableTiresList.length > 200 || tiresToUpdateList.length > 200) && <div className={classNames('ms-Grid-row')}>
            <div className={classNames('ms-Grid-col', 'ms-sm12', styles.statusWrapper)}>
              <Text variant='mediumPlus' className={styles.statusWrapper}>STATUS: { runningStatus } </Text>
            </div>
          </div>}
          <div className={classNames('ms-Grid-row')}>
            <div className={classNames('ms-Grid-col', 'ms-sm12', styles.marginTop10)}>
              <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                <div className={classNames('top-table', styles.marginTop10)}>
                  <PrimaryButton
                    id="transferToUpdateBtn"
                    text="Apply DN to Selected"
                    onClick={transferTiresToUpdate}
                    disabled={disableToUpdateButton || !userPermissions.isWrite}
                  />
                </div>
                <div className={classNames('tableContainer', styles.marginTop10)}>
                  <DataGridComponent
                    idTable={'available-intransit-table'}
                    title='Available In-Transit Tires'
                    headCells={availableTiresColumns}
                    rowsTable={availableTiresList}
                    defaultRowsPerPage={intransitTRRPageSizes}
                    preselectedRows={availableTiresSeriesSelected}
                    totalDataFound={totalFound}
                    isLoading={isLoadingAvailableTable}
                    enableCheckBox={true}
                    enableSearching={true}
                    enableMultiSelectRow={true}
                    enablePagination={true}
                    enableRowsPerPage={true}
                    reloadControlsTable={reloadControlsTable}
                    handleChangeDataGridState={handleChangeDataGridState}
                    handleSelectRow={handleSelectAvailableRow}
                  />
                </div>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm6')}>
                <div className={classNames('top-table', styles.marginTop10)}>
                  <PrimaryButton
                    id="transferToAvailableBtn"
                    text="Move selected back to available"
                    onClick={transferTiresToAvailable}
                    disabled={disableToAvailableAButton || !userPermissions.isWrite}
                  />
                </div>
                <div className={classNames('tableContainer', styles.marginTop10)}>
                  <DataGridComponent
                    idTable={'updated-consigned-table'}
                    title='Updated Consigned Tires'
                    headCells={updatedConsignedTiresColumns}
                    rowsTable={tiresToUpdateList}
                    defaultRowsPerPage={intransitTRRPageSizes}
                    totalDataFound={totalFoundUpdate}
                    isLoading={isLoadingTemporaryTable}
                    enableCheckBox={true}
                    enableSearching={true}
                    enableMultiSelectRow={true}
                    enablePagination={true}
                    enableRowsPerPage={true}
                    reloadControlsTable={reloadControlsTable}
                    handleChangeDataGridState={handleChangeUpdatedGridState}
                    handleSelectRow={handleSelectToUpdateRow}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames('ms-Grid-col', 'ms-sm12', 'buttonsWrapper')}>
          <DefaultButton onClick={toggleShowPrintExport} text="Print/Export" />
          <PrimaryButton
            id='updateBtn'
            onClick={toggleUpdatingConfirmation}
            text="Update"
            disabled={disableUpdateBtn || !userPermissions.isWrite}
          />
        </div>
      </div>
      <PrintingModal
        isOpened={showPrintExport}
        onClose={toggleShowPrintExport}
        onPrint={handlePrint}
      />
      <DialogComponent
        isOpen={isUpdatingDialogVisible}
        onCancel={toggleUpdatingConfirmation}
        onSubmit={updateTRR}
        title={'Confirmation'}
        subText={`Are you sure you want to update ${toUpdateTiresSelected.length} item(s) with TRR?`}
        onSubmitLabel={'Update'}
        onCancelLabel={'Cancel'}
      />
      <LocationsModalComponent
        id='locationModal'
        isOpen={isLocationModalOpen}
        title='Locations'
        customerId={customerId}
        onSubmit={handleOnLocationSubmit}
        onDismiss={toggleLocationModal}
        successLabel='Select Location'
        cancelLabel='Cancel'
      />
    </>
  );
};

export default UpdateWithTRR;