import { Checkbox, DatePicker, DefaultButton, Dropdown, MessageBarType, Text, TextField } from '@fluentui/react';
import classNames from 'classnames';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { useLocation, useParams } from 'react-router-dom';
import apiService from '../../../../../api';
import { auth_terms_general } from '../../../../../consts/programKeys';
import useNotifications from '../../../../../hooks/useNotifications';
import { useUserPermissions } from '../../../../../hooks/useUserPermissions';
import LoadingScreen from '../../../../LoadingScreen/LoadingScreen';
import SeparatorGy from '../../../../SeparatorGy/SeparatorGy';
import { goToSelection, paymentTerms, processMethods, renewalInitiators, rkTypes } from './consts';
import { TERMSSTATUS_ENUM } from '../../../../../models/EnumTermsStatuses';
import ContractShellModal from './ContractShellModal/ContractShellModal';
import styles from './General.module.scss';
import { IGeneralProps } from './IGeneralProps';
import { IGeneralData, IGeneralState } from './IGeneralState';
import { transformDate } from '../../../../../shared/transformDate';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomer, customerSelector } from '../../../../../redux/customerSlice';
import FinalizeModal from './FinalizeModal/FinalizeModal';

interface ParentContractStatus {
  contractStatus: string,
}

const General: FC<IGeneralProps> = () => {
  const [state, setState] = useState<IGeneralState>({
    loading: false,
  });

  const [runningProcess, setRunningProcess] = useState<string>('');
  const customerData = useSelector(customerSelector);

  const [showContractShell, { toggle: toggleShowContractShell }] = useBoolean(false);

  const dispatch = useDispatch();
  const [renewal, setRenewal] = useState<boolean>(true);
  const [isFinalizeModalOpen, setIsFinalizeModalOpen] = useState<boolean>(false);
  const location = useLocation<ParentContractStatus>();
  const { contractStatus = 'Empty' } = location.state || {};
  const [hideFinalizeandRevertButton, setHideFinalizeandRevertButton] = useState<boolean>((contractStatus === TERMSSTATUS_ENUM.ACTIVE || contractStatus === TERMSSTATUS_ENUM.CLOSEDOUT) ? true : false);
  const [enableGenerateButton, setEnableGenerateButton] = useState<boolean>((contractStatus === TERMSSTATUS_ENUM.UNDERPREPARATION) ? true : false);
  const [hideFinalizeStatusText, sethideFinalizeStatusText] = useState<boolean>(true);

  const [generalData, setGeneralData] = useState<IGeneralData>({
    activeStatus: '',
    effectivePeriod: 0,
    effectiveStartDate: null,
    originalEndDate: '',
    currentEndDate: null,
    runoutPeriod: '',
    brandPrefix: '',
    testAgreement: false,
    processMethod: '',
    optionPeriod: 0,
    noOfOptions: 0,
    currentOption: 0,
    renewalInitiator: '',
    paymentTerms: '',
    renewalBeforeDays: 0,
    rK_Type: '1',
  });
  const [initialGeneralData, setInitialGeneralData] = useState<IGeneralData>({
    activeStatus: '',
    effectivePeriod: 0,
    effectiveStartDate: null,
    originalEndDate: '',
    currentEndDate: null,
    runoutPeriod: '',
    brandPrefix: '',
    testAgreement: false,
    processMethod: '',
    optionPeriod: 0,
    noOfOptions: 0,
    currentOption: 0,
    renewalInitiator: '',
    paymentTerms: '',
    renewalBeforeDays: 0,
    rK_Type: '1',
  });
  const { hasPermission } = useUserPermissions();
  const userPermissions = hasPermission(auth_terms_general);

  const { id } = useParams<{ id: string }>();
  const { contractId } = useParams<{ contractId: string }>();

  const { addNotification } = useNotifications();
  const [isCheckedTestAgreement, setIsCheckedTestAgreement] = useBoolean(false);

  const fetchCustomers = async () => {
    try {
      const { data: { data } }: any = await apiService.getCustomerSearch({}, null, null, id);
      dispatch(setCustomer(data[0]));

    } catch (e: any) {
      addNotification({
        text: 'Customers fetching error',
        type: MessageBarType.error,
      });
    }
  };

  const postFinalizeTerm = async () => {
    setState(prev => ({ ...prev, loading: true }));
    setIsFinalizeModalOpen(false);
    try {
      const { data }: any = await apiService.billingAPI.finalizeTerm({
        contractId,
        renewal,
        userRefused: false,
      }); 
      addNotification({
        text: data?.message,
        type: MessageBarType.info,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Finalize Error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
      fetchTerm();
      fetchContract();
      getFinalizeContractStatus();
    }
  };

  const fetchTerm = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.generalAPI.getTermGeneral(
        contractId,
        id,
      );
      setState((prev: any) => ({
        ...prev,
      }));
      setInitialGeneralData(data);
      setGeneralData(data);
      data.fTestAgreement === 'N' ? setIsCheckedTestAgreement.setFalse() : setIsCheckedTestAgreement.setTrue();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Term fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchContract = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const { data }: any = await apiService.contract.getContractDocuments(
        null,
        null,
        '',
        id,
        contractId,
      );
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Contract Documents fetching error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const fetchRevertContractStatus = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.terms.revertContractStatus(contractId);
      addNotification({
        text: 'Status reverted successfully',
        type: MessageBarType.success,
      });
    } catch (error: any) {
      const { response } = error;
      addNotification({
        text: `Revert status error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const saveTerm = async (termData: IGeneralData) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const currentEndDate = termData.currentEndDate ? transformDate(moment(termData.currentEndDate).format('MM/DD/YYYY')) : null;
      const effectiveStartDate = termData.effectiveStartDate ? transformDate(moment(termData.effectiveStartDate).format('MM/DD/YYYY')) : null;
      const originalEndDate = termData.originalEndDate ? transformDate(moment(termData.originalEndDate).format('MM/DD/YYYY')) : null;

      await apiService.generalAPI.updateTermGeneral({
        ...termData,
        status: contractStatus,
        customerId: id,
        testAgreement: isCheckedTestAgreement,
        currentEndDate: currentEndDate,
        effectiveStartDate: effectiveStartDate,
        originalEndDate: originalEndDate,
      });
      addNotification({
        text: 'Terms General Information successfully saved.',
        type: MessageBarType.success,
      });
      await fetchCustomers();
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Term saving error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const generateTerm = async (shellName: string) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      await apiService.terms.generateTerm(
        contractId,
        id,
        shellName,
      );
      addNotification({
        text: 'Term successfully generated.',
        type: MessageBarType.success,
      });
      await fetchContract();
      await fetchCustomers();
      setEnableGenerateButton(false);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Term generating error: ${response.data.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
      toggleShowContractShell();
    }
  };

  const getAppliedEndDate = () => {
    switch (generalData.activeStatus) {
      case 'NORMAL': {
        const appliedEndDate = moment(generalData.currentEndDate?.toString()).add(generalData.effectivePeriod, 'M').toDate().toString();
        setGeneralData(prev => ({ ...prev, currentEndDate: appliedEndDate.toString() }));
        return appliedEndDate;
      }
      case 'OPTION': {
        const appliedEndDate = moment(generalData.currentEndDate?.toString()).add(generalData.optionPeriod, 'M').toDate().toString();
        setGeneralData(prev => ({ ...prev, currentEndDate: appliedEndDate.toString(), currentOption: (+prev.currentOption + 1) }));
        return appliedEndDate;
      }
      case 'NORMAL RUNOUT': {
        const appliedEndDate = moment(generalData.currentEndDate?.toString()).add(generalData.runoutPeriod, 'M').toDate().toString();
        setGeneralData(prev => ({ ...prev, currentEndDate: appliedEndDate.toString() }));
        return appliedEndDate;
      }
      default:
        return generalData.currentEndDate;
    }
  };

  const handleApply = () => generalData.activeStatus === 'OPTION' ? (generalData.currentOption !== generalData.noOfOptions
    ? updateGoTo() : addNotification({ text: 'Error: Current option = # of option.', type: MessageBarType.error })) : updateGoTo();

  const updateGoTo = async () => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const payload = {
        contractId: contractId,
        customerId: id,
        activeStatus: generalData.activeStatus,
      };
      await apiService.terms.updateGoTo(payload);
    } catch (e: any) {
      const { response } = e;
      addNotification({
        text: `Error updating GoTo in terms API: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
      await saveTerm({ ...generalData, currentEndDate: getAppliedEndDate() });
    }
  };

  const onChangeField = (field: any, value: any, regExp?: RegExp) => {
    setGeneralData((prev: any) => ({ ...prev, [field]: regExp ? (regExp.test(value) ? value : prev[field]) : value }));
  };


  const getFinalizeContractStatus = async () => {
    let timeOut = null;
    setState(prev => ({ ...prev, loading: true }));
    
    try {
      
      const { data: details }: any = await apiService.billingAPI.finalizeTermStatus({
        contractId,
      });
      
      setRunningProcess(details.runStatus);

      if(timeOut) clearInterval(timeOut);

      if (details.runStatus == 'RUNNING')timeOut= setTimeout(() => getFinalizeContractStatus(), 10000);
      
      if (details.runStatus == 'RUNNING' || details.runStatus == 'ERROR' || details.runStatus == 'COMPLETED')sethideFinalizeStatusText(false);

      if(details.runStatus == 'ERROR')
      {
        addNotification({
          text: 'Get Finalize Error:' + details.errorMsg,
          type: MessageBarType.error,
        });
      }
        
      
    } catch (error: any) {
      const { response } = error;
      
      addNotification({
        text: `Get Finalize Error: ${response?.data?.message}`,
        type: MessageBarType.error,
      });
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchTerm();
    fetchContract();
    getFinalizeContractStatus();
  }, []);


  useEffect(() => {
    const generalEffectivePeriod =
      typeof generalData?.effectivePeriod === 'string' ?
        parseInt(generalData?.effectivePeriod) :
        generalData?.effectivePeriod;
    const generalDataEffectiveStartDate =
      typeof generalData?.effectiveStartDate === 'string' ?
        generalData?.effectiveStartDate :
        moment(generalData.effectiveStartDate).format('M/D/YYYY hh:mm:ss A');


    if (initialGeneralData?.effectivePeriod === generalEffectivePeriod
      && initialGeneralData?.effectiveStartDate === generalDataEffectiveStartDate) {

      onChangeField('originalEndDate', initialGeneralData?.originalEndDate);
      onChangeField('currentEndDate', initialGeneralData?.currentEndDate);

    } else if (customerData?.contractStatus !== TERMSSTATUS_ENUM.CLOSEDOUT &&
      initialGeneralData?.effectivePeriod !== generalEffectivePeriod ||
      initialGeneralData?.effectiveStartDate !== generalDataEffectiveStartDate) {

      if (customerData?.contractStatus === TERMSSTATUS_ENUM.UNDERPREPARATION || customerData?.contractStatus === TERMSSTATUS_ENUM.GENERATED) {
        const endDate = moment(generalData?.effectiveStartDate).add(generalData?.effectivePeriod, 'M').format('MM/DD/YYYY');
        onChangeField('originalEndDate', endDate);
        onChangeField('currentEndDate', endDate);
      } else if (customerData?.contractStatus === TERMSSTATUS_ENUM.ACTIVE) {
        const originalEndDate = moment(generalData?.effectiveStartDate).add(generalData?.effectivePeriod, 'M').format('MM/DD/YYYY');
        onChangeField('originalEndDate', originalEndDate);
      }
    }
  }, [generalData.effectivePeriod, generalData.effectiveStartDate]);


  return (
    <>
      <div className="ms-Grid margin-left-rigth-2 marginTop18" dir='ltr'>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm8')}>
            <div>
              <Text variant="xLarge" className={styles.highlight}>Terms and Conditions</Text>
              <SeparatorGy />
              <div className={classNames('ms-Grid-row', styles.inputRow)}>
                <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                  <TextField
                    data-testid='effective-period-months'
                    value={generalData.effectivePeriod?.toString()}
                    label='Effective Period (months)'
                    onChange={(e, effectivePeriod) => onChangeField('effectivePeriod', effectivePeriod, /^[0-9]*$/)}
                    placeholder='Effective Period (months)'
                    disabled={customerData?.contractStatus === TERMSSTATUS_ENUM.CLOSEDOUT}
                  />
                </div>
                <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                  <DatePicker
                    value={generalData.effectiveStartDate !== null ? moment(generalData.effectiveStartDate).toDate() : undefined}
                    label='Effective Start Date'
                    onSelectDate={(date: Date | null | undefined) => onChangeField('effectiveStartDate', date)}
                    formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                    placeholder='Effective Start Date'
                    disabled={customerData?.contractStatus === TERMSSTATUS_ENUM.CLOSEDOUT}
                  />
                </div>
                <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                  <TextField
                    value={moment(generalData.originalEndDate).format('MM/DD/YYYY')}
                    label='Original End Date'
                    placeholder='Original End Date'
                    disabled
                  />
                </div>
              </div>
              <div className={classNames('ms-Grid-row', styles.inputRow)}>
                <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                  <DatePicker
                    value={generalData.currentEndDate !== null ? moment(generalData.currentEndDate).toDate() : undefined}
                    label='Current End Date'
                    onSelectDate={(date: Date | null | undefined) => onChangeField('currentEndDate', date)}
                    formatDate={(date: any) => moment(date).format('MM/DD/YYYY')}
                    placeholder='Current End Date'
                    disabled={customerData?.contractStatus === TERMSSTATUS_ENUM.CLOSEDOUT}
                  />
                </div>
                <div className={classNames('ms-Grid-col', 'ms-sm4')}>
                  <TextField
                    value={generalData.runoutPeriod}
                    label='Runout Period (months)'
                    onChange={(e, runoutPeriod) => onChangeField('runoutPeriod', runoutPeriod, /^[0-9]*$/)}
                    placeholder='Runout Period (months)'
                    disabled={customerData?.contractStatus === TERMSSTATUS_ENUM.CLOSEDOUT}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classNames('ms-Grid-col', 'ms-sm4')}>
            <div className={classNames('ms-Grid-row', styles.inputRow)}>
              <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                <TextField
                  value={generalData.brandPrefix}
                  label='Brand Prefix'
                  onChange={(e, brandPrefix) => onChangeField('brandPrefix', brandPrefix)}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-row', styles.inputRow)}>
              <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                <Checkbox
                  label='Test Agreement'
                  checked={isCheckedTestAgreement}
                  onChange={setIsCheckedTestAgreement.toggle}
                />
              </div>
            </div>
            <div className={classNames('ms-Grid-row', styles.inputRow)}>
              <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                <Dropdown
                  options={rkTypes.map((item: any) => ({
                    key: item.key,
                    text: item.text,
                  }))}
                  label='Record Keeping Type'
                  selectedKey={generalData.rK_Type}
                  onChange={(e, { key: rK_Type }: any) => onChangeField('rK_Type', rK_Type)}
                  required />
                  
              </div>
            </div>
            <div className={classNames('ms-Grid-row', styles.inputRow)}>
              <div style={{ display: 'inline-flex', width: '100%', alignItems: 'end' }}>
                <div className={classNames('ms-Grid-col', 'ms-sm12')} style={{ width: '70%' }}>
                  <Dropdown
                    options={goToSelection.map((item: any) => ({
                      key: item,
                      text: item,
                    }))}
                    label='Go To'
                    selectedKey={generalData.activeStatus}
                    onChange={(e, { key: activeStatus }: any) => onChangeField('activeStatus', activeStatus)} />
                </div>
                <div className="ms-Grid-col" style={{ width: '30%' }}>
                  <DefaultButton onClick={handleApply} text="Apply" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={classNames('ms-Grid-col', 'ms-sm8')}>
            <div>
              <Text variant="xLarge" className={styles.highlight}>Options</Text>
              <SeparatorGy />
            </div>
            <div className={classNames('ms-Grid-row', styles.inputRow)}>
              <div className={classNames('ms-Grid-col', 'ms-sm5')}>
                <div className="ms-Grid-row">
                  <div className={classNames('ms-Grid-col', 'ms-sm7')}>
                    <TextField
                      value={generalData.optionPeriod?.toString()}
                      label='Period (months)'
                      onChange={(e, optionPeriod) => onChangeField('optionPeriod', optionPeriod, /^[0-9]*$/)}
                      placeholder='Period (months)' />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm5')}>
                    <TextField
                      value={generalData.noOfOptions?.toString()}
                      label='# of Options'
                      onChange={(e, noOfOptions) => onChangeField('noOfOptions', noOfOptions, /^[0-9]*$/)}
                      placeholder='# of Options' />
                  </div>
                </div>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm7')}>
                <div className="ms-Grid-row">
                  <div className={classNames('ms-Grid-col', 'ms-sm7')}>
                    <TextField
                      value={generalData.renewalBeforeDays?.toString()}
                      label='Renewal Notice (days)'
                      onChange={(e, renewalBeforeDays) => onChangeField('renewalBeforeDays', renewalBeforeDays, /^[0-9]*$/)}
                      placeholder='Renewal Notice (days)'
                    />
                  </div>
                  <div className={classNames('ms-Grid-col', 'ms-sm5')}>
                    <Dropdown
                      options={renewalInitiators.map((item: any) => ({
                        key: item,
                        text: item,
                      }))}
                      label='Renewal Initiator'
                      selectedKey={generalData.renewalInitiator}
                      onChange={(e, { key: renewalInitiator }: any) => onChangeField('renewalInitiator', renewalInitiator)}
                      placeholder='Renewal Initiator'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames('ms-Grid-row', styles.inputRow)}>
              <div className={classNames('ms-Grid-col', 'ms-sm5')}>
                <div className="ms-Grid-row">
                  <div className={classNames('ms-Grid-col', 'ms-sm12')}>
                    <TextField
                      value={generalData.currentOption?.toString()}
                      label='Current Option'
                      onChange={(e, currentOption) => onChangeField('currentOption', currentOption, /^[0-9]*$/)}
                      placeholder='Current Option' />
                  </div>
                </div>
              </div>
              <div className={classNames('ms-Grid-col', 'ms-sm7')}>
                <div className="ms-Grid-row">
                  <div className={classNames('ms-Grid-col', 'ms-sm7')}>
                    <Dropdown
                      options={paymentTerms.map((item: any) => ({
                        key: item,
                        text: item,
                      }))}
                      label='Payment Terms'
                      selectedKey={generalData.paymentTerms}
                      onChange={(e, { key: paymentTerms }: any) => onChangeField('paymentTerms', paymentTerms)}
                      placeholder='Payment Terms'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className={classNames('ms-Grid-col', 'ms-sm12', styles.buttonsWrapper)}>
          {
            (!enableGenerateButton && !hideFinalizeandRevertButton) && <DefaultButton
              onClick={() => { setIsFinalizeModalOpen(true); }}
              text="Finalize"
              disabled={!userPermissions.isWrite} />
          }
          {
            enableGenerateButton && <DefaultButton
              onClick={toggleShowContractShell}
              text={state.loading ? '...' : 'Generate'}
              disabled={!userPermissions.isWrite} />
          }
          {
            (!enableGenerateButton && !hideFinalizeandRevertButton) && <DefaultButton
              onClick={fetchRevertContractStatus}
              text={state.loading ? '...' : 'Revert'}
              disabled={!userPermissions.isWrite} />
          }

          <DefaultButton onClick={() => saveTerm(generalData)} data-testid='save-button' text="Save Term" disabled={!userPermissions.isWrite} />
        </div>
      </div>
      <div className={classNames('ms-Grid-row', styles.statusWrapper)}>
        <div className={classNames('ms-Grid-col', styles.marginTop20)}>
          {(!hideFinalizeStatusText && <Text variant='mediumPlus' className={styles.highlight}>FINALIZE STATUS: { runningProcess } </Text>)}
        </div>
      </div>
      <FinalizeModal
        isOpen={isFinalizeModalOpen}
        onCancel={() => setIsFinalizeModalOpen(false)}
        onSubmit={postFinalizeTerm}
        onOptionChange={(renewal) => { setRenewal(renewal); }}
      />
      <ContractShellModal
        isOpened={showContractShell}
        onClose={toggleShowContractShell}
        method={{ name: 'Generate', function: generateTerm }}
      />
      {state.loading && <LoadingScreen />}
    </>
  );
};

export default General;