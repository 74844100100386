import { recordTestInstance } from '../axios';

const uploadB73Format = (customerId: any, locationId: any, file: any) => {
  const formData = new FormData();
  formData.append('customerId', customerId);
  formData.append('locationId', locationId);
  formData.append('file', file);

  return recordTestInstance.post('/tire-change/b73format/upload', formData);
};

const uploadPostMonthlyMiles = (customerId: any, locationId: any, billingPeriodId: any, file: any) => {
  const formData = new FormData();
  formData.append('customerId', customerId);
  formData.append('locationId', locationId);
  formData.append('billingPeriodId', billingPeriodId);
  formData.append('file', file);

  return recordTestInstance.post('/post-monthly-miles/upload', formData);
};

const uploadMassDetails = (customerId: string, locationId: string, file: any) => {
  const formData = new FormData();
  formData.append('customerId', customerId);
  formData.append('locationId', locationId);
  formData.append('file', file);

  return recordTestInstance.post('/mass-details/upload', formData);
};

const uploadMassDetailsStatus = (customerId: string, locationId: string) => {
  return recordTestInstance.post('/mass-details/upload/status', { customerId, locationId });
};

const uploadRegisteredVehicles = (customerId: string, locationId: string, regDate: any, file: any) => {
  const formData = new FormData();
  formData.append('customerId', customerId);
  formData.append('locationId', locationId);
  formData.append('regDate', regDate);
  formData.append('file', file);

  return recordTestInstance.post('/registered-vehicles/upload', formData);
};

const uploadRegisteredVehiclesStatus = (customerId: string, locationId: string, regDate: any) => {
  return recordTestInstance.post('/registered-vehicles/upload/status', { customerId, locationId, regDate });
};


const getPostMonthlyMilesUploadList = (customerId: any, locationId: any, pagination: any, sortOrder: any) => 
  recordTestInstance.post('/post-monthly-miles/upload/list', { customerId, locationId, pagination, sortOrder });

const getTireChangeUploadList = (customerId: any, locationId: any, pagination: any, sortOrder: any) => 
  recordTestInstance.post('/tire-change/b73format/upload/list', { customerId, locationId, pagination, sortOrder });

const submitPostMonthlyMilesUpload = (customerId: any, locationId: any) => 
  recordTestInstance.post('/post-monthly-miles/upload/submit', { customerId, locationId });

const submitTireChangeUpload = (customerId: any, locationId: any) => 
  recordTestInstance.post('/tire-change/b73format/upload/submit', { customerId, locationId });

const savePostMonthlyMilesUpload = (data: any, customerId: any, locationId: any) =>
  recordTestInstance.post('/post-monthly-miles/upload/save', { data, customerId, locationId });

const saveTireChangeUpload = (data: any, customerId: any, locationId: any) =>
  recordTestInstance.post('/tire-change/b73format/upload/save', { data, customerId, locationId });

export default {
  uploadB73Format,
  uploadPostMonthlyMiles,
  uploadMassDetails,
  uploadMassDetailsStatus,
  uploadRegisteredVehicles,
  uploadRegisteredVehiclesStatus,
  getPostMonthlyMilesUploadList,
  getTireChangeUploadList,
  submitPostMonthlyMilesUpload,
  submitTireChangeUpload,
  savePostMonthlyMilesUpload,
  saveTireChangeUpload,
};