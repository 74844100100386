import { billingTestInstance, exportTestInstance } from '../axios';

const getAvailableInvoices = (
  pagination: any,
  sortOrder: any,
  filters: any,
  customerId: string,
  sapInvoiceNumber: any,
  regionCode: any,
  country: any,
) => billingTestInstance.post('/view-invoices/available-invoices', { pagination, sortOrder, filters, customerId, sapInvoiceNumber, regionCode, country });

const deleteAvailableInvoices = (ids: any) =>
  billingTestInstance.delete('/view-invoices/invoice/delete', { data: { ids } });

const getViewInvoiceCustomerDetails = (invoiceId: string) =>
  billingTestInstance.post('/view-invoices/customer-details', { invoiceId });

const getInvoiceTypes = () => billingTestInstance.get('/view-invoices/invoice-types');
const getInvoiceStatuses = () => billingTestInstance.get('/contract/statuses');

const postHold = (payload: any) =>
  billingTestInstance.post('/view-invoices/invoice/hold', payload);

const postRevert = (payload: any) =>
  billingTestInstance.post('/view-invoices/invoice/revert', payload);

const postRelease = (payload: any) =>
  billingTestInstance.post('/view-invoices/invoice/release', payload);

const bulkRelease = (
  CustomerId: any,
  RegionCode: any,
  Country: any,
  Ids: any,
  InvoiceType: any,
  CurrentYear: any,
  CreditOnly: any,
  DuringTheMonth: any,
  ReleaseAll: any,
  ByViewSelection: any,
) =>
  billingTestInstance.post('/bulk/invoice/release', {
    CustomerId,
    RegionCode,
    Country,
    Ids,
    InvoiceType,
    CurrentYear,
    CreditOnly,
    DuringTheMonth,
    ReleaseAll,
    ByViewSelection,
  });
  
const bulkReleaseStatus = (
  CustomerId: any,
  RegionCode: any,
  Country: any,
  Ids: any,
  InvoiceType: any,
  CurrentYear: any,
  CreditOnly: any,
  DuringTheMonth: any,
  ReleaseAll: any,
  ByViewSelection: any,
) =>
  billingTestInstance.post('/bulk/invoice/status', {
    CustomerId,
    RegionCode,
    Country,
    Ids,
    InvoiceType,
    CurrentYear,
    CreditOnly,
    DuringTheMonth,
    ReleaseAll,
    ByViewSelection,
  });
  
const bulkReleaseResults = (
  CustomerId: any,
  RegionCode: any,
  Country: any,
  Ids: any,
  InvoiceType: any,
  CurrentYear: any,
  CreditOnly: any,
  DuringTheMonth: any,
  ReleaseAll: any,
  ByViewSelection: any,
  pagination: any,
  sortOrder: any,
) =>
  billingTestInstance.post('/bulk/invoice/results', {
    CustomerId,
    RegionCode,
    Country,
    Ids,
    InvoiceType,
    CurrentYear,
    CreditOnly,
    DuringTheMonth,
    ReleaseAll,
    ByViewSelection,
    pagination,
    sortOrder,
  });

const getMaintainInvoiceCustomerDetails = (invoiceId: string) =>
  billingTestInstance.post('/invoice/customer-details', { invoiceId });

const getMaintainInvoiceContractDetails = (invoiceId: string | undefined, customerId: string) =>
  billingTestInstance.post('/invoice/contract-details', { invoiceId, customerId });

const addInvoiceItem = (payload: any) =>
  billingTestInstance.post('invoice/item/add', payload);

const updateInvoiceItem = (payload: any) =>
  billingTestInstance.put('invoice/item', payload);

const deleteInvoiceItem = (ids: Array<any>) =>
  billingTestInstance.delete('invoice/item', { data: { ids } });

const saveInvoice = (payload: any) =>
  billingTestInstance.post('invoice/save', payload);

const printExcelInvoices = (input: any) =>
  exportTestInstance.post('/view-invoices/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfInvoices = (input: any) =>
  exportTestInstance.post('/view-invoices/export-pdf', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
  });

const getReportById = (input: {
  ReportId: string;
}) =>
  exportTestInstance.post('/view-invoices/getReport-by-report-id', input);

const downloadReportById = (input: {
  ReportId: string;
}) =>
  exportTestInstance.post('view-invoices/downloadInvoices', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const getContractList = (
  pagination: any,
  sortOrder: any,
  filters: any,
  customerId: string,
  regionCode: any,
) => billingTestInstance.post('/contract-listing/contracts', { pagination, sortOrder, filters, customerId, regionCode });

const getInvoiceDetails = (invoiceId: string) =>
  billingTestInstance.post('/invoice/details', { invoiceId });

const getCreditAgainst = (invoiceId: string) =>
  billingTestInstance.post('/invoice/credit-against', { invoiceId });

const getBillingTypes = () =>
  billingTestInstance.get('/registered-tire/billing-types');

const getInvoiceItems = (invoiceId: any, pagination: any, sortOrder: any, filterText: any = null) =>
  billingTestInstance.post('/invoice/invoice-items-by-id', { invoiceId, pagination, sortOrder, filterText });

const createInvoice = (params: any) =>
  billingTestInstance.post('/invoice/create', params);

const printExcelInvoiceItems = (input: any) =>
  exportTestInstance.post('/invoice/invoice-items-by-id/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfInvoiceItems = (input: any) =>
  exportTestInstance.post('/invoice/invoice-items-by-id/export-pdf', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });
const getAvailableQuotesByCustomer = (
  pagination: any,
  sortOrder: any,
  filters: any,
  customerId: string,
) => billingTestInstance.post('/maintain-tire-sale-quotes/avaiable-quotes', { pagination, sortOrder, filters, customerId });

const getBillingCodesList = (
  pagination: any,
  sortOrder: any,
  filters: any,
  customerId: string,
  locationId: string,
) => billingTestInstance.post('/set-billing-codes/billing-codes/list', { pagination, sortOrder, filters, customerId, locationId });

const getBillingOptionsList = (
  pagination: any,
  sortOrder: any,
  filters: any,
  customerId: string,
  locationId: string,
) => billingTestInstance.post('/set-billing-options/billing-options/list', { pagination, sortOrder, filters, customerId, locationId });

const listPostMileageAdjustments = (payload: any) =>
  billingTestInstance.post('/post-mileage-adjustments/list', payload);

const addPostMileageAdjustments = (payload: any) =>
  billingTestInstance.post('/post-mileage-adjustments/add', payload);

const updatePostMileageAdjustments = (payload: any) =>
  billingTestInstance.post('/post-mileage-adjustments/update', payload);

const deletePostMileageAdjustments = (ids: any) =>
  billingTestInstance.delete('post-mileage-adjustments/delete', { data: { ids } });

const saveCommentsPostMileage = (payload: any) =>
  billingTestInstance.post('/post-mileage-adjustments/save-comments', payload);

const calculateMiles = (payload: any) =>
  billingTestInstance.post('post-mileage-adjustments/calculate', payload);

const approveMiles = (payload: any) =>
  billingTestInstance.post('post-mileage-adjustments/approve', payload);

const submitMiles = (payload: any) =>
  billingTestInstance.post('post-mileage-adjustments/submit', payload);

const printExcelPostMileage = (input: any) =>
  exportTestInstance.post('post-mileage-adjustments/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPDFPostMileage = (input: any) =>
  exportTestInstance.post('post-mileage-adjustments/export-pdf', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const updateBillingCodesList = (
  billingCode: any,
) => billingTestInstance.put('/set-billing-codes/billing-codes', { data: [billingCode] });

const updateBillingOptionsList = (
  billingCode: any,
) => billingTestInstance.put('/set-billing-options/billing-options', { data: [billingCode] });

const deleteQuotes = (ids: Array<any>) =>
  billingTestInstance.delete('/maintain-tire-sale-quotes/avaiable-quotes', { data: { ids } });

const getPendingMileageRuns = (filters: any, pagination: any, sortOrder: any) =>
  billingTestInstance.post('/pending-mileage-run', { filters, pagination, sortOrder });

const estimatePendingMileageRuns = (estimatedBillFor: any[]) =>
  billingTestInstance.post('/pending-mileage-run/estimate', { estimatedBillFor });

const printExcelPendingMileageRuns = (input: any) =>
  exportTestInstance.post('/pending-mileage-run/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfPendingMileageRuns = (input: any) =>
  exportTestInstance.post('/pending-mileage-run/export-pdf', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });
const createQuote = (customerId: string) =>
  billingTestInstance.put('/maintain-tire-sale-quotes/create', { customerId });

const getQuoteDetails = (quoteId: string) =>
  billingTestInstance.post('/tire-sale-quotes/quote', { quoteId });

const getTiresQuote = (quoteId: string, pagination: any, sortOrder: any, filters: any) =>
  billingTestInstance.post('/tire-sale-quotes/quote/tires', { quoteId, pagination, sortOrder, filters });

const setCloseoutBillingDate = (contractId: any, closeoutDate: string) =>
  billingTestInstance.put('/closeout-billing/set-date', { contractId, closeoutDate });

const getCloseoutBillingTires = (
  customerId: string,
  locationId: string,
  contractId: any,
  pagination: any,
  sortOrder: any,
  refreshTable: any,
  file?: any) => {
  const formData = new FormData();
  formData.append('customerId', customerId);
  formData.append('contractId', contractId);
  formData.append('paginationPageSize', pagination?.pageSize);
  formData.append('paginationPageNumber', pagination?.pageNumber);
  formData.append('sortOrderColumn', sortOrder?.column);
  formData.append('sortOrderOrder', sortOrder?.order);
  formData.append('refreshTable', refreshTable);
  if (locationId)
    formData.append('locationId', locationId);
  if (file) {
    formData.append('fromFile', 'true');
    formData.append('file', file);
  }
  else {
    formData.append('fromFile', 'false');
  }
  return billingTestInstance.post('/closeout-billing/list', formData);
};

const getCloseoutBillingErrors = (pagination: any, sortOrder: any, customerId: string, locationId: string) =>
  billingTestInstance.post('/closeout-billing/errors', { pagination, sortOrder, customerId, locationId });

const submitCloseoutBilling = (postTirexferIds: any[], customerId: string, clouseOutDt: any) =>
  billingTestInstance.post('/closeout-billing/submit', { postTirexferIds, customerId, clouseOutDt });

const updateCloseoutBilling = (closeoutSubmitTDInputDTOList: Array<any>, customerId: string) =>
  billingTestInstance.post('/closeout-billing/submittd', { closeoutSubmitTDInputDTOList, customerId });

const readyToBillCloseoutBilling = (payload: any) =>
  billingTestInstance.post('/closeout-billing/ready-to-bill', payload);

const printExcelCloseoutBilling = (input: any) =>
  exportTestInstance.post('/terms/close-out-tires/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfCloseoutBilling = (input: any) =>
  exportTestInstance.post('/terms/close-out-tires/export-pdf', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printInvoiceReport = (input: any) =>
  exportTestInstance.post('/view-invoices/report', input);

const printInvoiceReportExcel = (input: any) =>
  exportTestInstance.post('/view-invoices/excelreport', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  });

const getMyReports = (pagination?: any, sortOrder?: any) =>
  exportTestInstance.post('/view-invoices/getInvoicesReports', { pagination, sortOrder });

const getAvailableReports = (invoiceId: string, reportType: string, module: string, reportFormat: string) =>
  exportTestInstance.post('/view-available-invoices/report', { invoiceId, reportType, module, reportFormat });

const downloadAvailableReport = (input: { reportId: string; reportName?: string; userId?: string; }) =>
  exportTestInstance.post('/view-available-invoices/download', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const downloadMyReport = (input: { reportId: string; reportName?: string; userId?: string; }) =>
  exportTestInstance.post('/view-invoices/downloadInvoices', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const deleteReports = (ids: Array<any>) =>
  exportTestInstance.delete('/view-invoices/report/delete', { data: { ids } });

const updateQuoteDetails = (body: any) =>
  billingTestInstance.put('/tire-sale-quotes/quote', body);

const addNewTires = (bodyRequest: any) =>
  billingTestInstance.put('/tire-sale-quotes/quote/tires', bodyRequest);

const uploadTreadDepth = (quoteId: any, sold: any, manual: any, file: any) => {
  const formData = new FormData();
  formData.append('quoteId', quoteId);
  formData.append('sold', sold);
  formData.append('manual', manual);
  formData.append('file', file);

  return billingTestInstance.post('/tread-depth/upload', formData, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });
};

const deleteTires = ({ ids, fclosedout }: any) =>
  billingTestInstance.delete('/tire-sale-quotes/quote/tires', { data: { ids, fclosedout } });

const saveQuote = (bodyRequest: any) =>
  billingTestInstance.put('/tire-sale-quotes/quote/tires/save', bodyRequest);

const verifyQuote = (bodyRequest: any) =>
  billingTestInstance.post('/tire-sale-quotes/quote/tires/verify', bodyRequest);

const releaseQuote = (bodyRequest: any) =>
  billingTestInstance.post('/tire-sale-quotes/quote/tires/release', bodyRequest);

const viewErrors = (pagination: any, sortOrder: any, quoteId: string) =>
  billingTestInstance.post('/tire-sale-quotes/error', { pagination, sortOrder, quoteId });

const printExcelViewTireSales = (input: any) =>
  exportTestInstance.post('/maintain-tire-sale-quotes/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfViewTireSales = (input: any) =>
  exportTestInstance.post('/maintain-tire-sale-quotes/export-pdf', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const printExcelTireSaleQuotes = (input: any) =>
  exportTestInstance.post('/billing-records/export-excel', input, {
    headers:
    {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  });

const printPdfViewTireSaleQuotes = (input: any) =>
  exportTestInstance.post('/billing-records/export-pdf', input, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.pdf',
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  });

const getBackoutDetails = (customerId: number) =>
  billingTestInstance.post('/mileage-run/back-out/details', { customerId });

const startBackout = (del: any, backOutMessage: string, invoiceId: number[], customerId: number) =>
  billingTestInstance.post('/mileage-run/back-out/start', { delete: del, backOutMessage, invoiceId, customerId });

const startBackoutStatus = (invoiceId: number, customerId: number, del: any, backOutMessage: any) =>
  billingTestInstance.post('/mileage-run/back-out/start/status', { delete: del, backOutMessage, invoiceId, customerId });

const startBackoutDetails = (invoiceId: number, customerId: number, del: any, backOutMessage: any) =>
  billingTestInstance.post('/mileage-run/back-out/start/details', { delete: del, backOutMessage, invoiceId, customerId });

const getCloseoutBillingContractDetails = (customerId: string) =>
  billingTestInstance.get('/closeout-billing/contract/' + customerId);

const getApplicableInvoiceTypes = (contractId: string) =>
  billingTestInstance.post('/invoice/applicable-invoice-types', { contractId });

const getAditionalDetails = (customerId: string, locationId: string, invoiceType: string, crDb: string) =>
  billingTestInstance.post('/invoice/addl-lines', { customerId, locationId, invoiceType, crDb });

const getDefaultInvoiceValues = (invoiceId: string, radialBlas: string, qty: number, unitPrice: number) =>
  billingTestInstance.post('/invoice/defaults', { invoiceId, radialBlas, qty, unitPrice });

const setBillingConditions = (payload: {
  customerId: string,
  mergeAll: boolean,
  allCredits: boolean,
  separateInvoicePerContract: boolean,
  separateInvoicePerService: boolean,
  separateInvoicePerEquipment: boolean,
  vehicleMileageReport: boolean,
  tireMileageReport: boolean
}) =>
  billingTestInstance.put('/set-billing-options/billing-conditions', payload);

const getBillingConditions = (payload: {
  customerId: string,
}) =>
  billingTestInstance.post('/set-billing-options/billing-conditions', payload);

const getTotalAndDescription = (invoiceId: string, qty: number, unitPrice: number) =>
  billingTestInstance.post('/invoice/defaults/desc-total', { invoiceId, qty, unitPrice });

const getMBCode = (invoiceId: string, radialBias: string) =>
  billingTestInstance.post('/invoice/defaults/mbcode', { invoiceId, radialBias });

const finalizeTerm = (payload: {
  contractId: string,
  renewal: boolean,
  userRefused: boolean
}) =>
  billingTestInstance.post('/maintain-contract/finalize', payload);

const getCreditAgainstInvoices = (
  pagination: any,
  sortOrder: any,
  filterText: string,
  customerId: string,
  invoiceType: string,
) => billingTestInstance.post('/invoice/credit-against/invoices', {
  pagination,
  sortOrder,
  filterText,
  customerId,
  invoiceType,
});

const getTireSaleQuoteVehicles = (searchedText: any, pagination: any, sortOrder: any, locationId: any, customerId: any) =>
  billingTestInstance.post('/tire-sale-quotes/vehicles', { pagination, searchedText, sortOrder, customerId, locationId });

const getRegionList = () =>
  billingTestInstance.get('/contract-listing/regions');

const copyInvoices = (payload: any) =>
  billingTestInstance.post('invoice/item/copy', payload);  

const getRecordPage = (pagination: any, sortOrder: any, quoteId: string, filters: any) =>
  billingTestInstance.post('/tire-sale-quotes/soldandlosttire/number-page',  { pagination, sortOrder, quoteId, filters});

const updateSoldAndLostTire = (consignedTireId: string, response: 'Y' | 'N') => {
  return billingTestInstance.put('/tire-sale-quotes/soldandlosttire', {
    consignedTireId,
    response,
  });
};

const getCloseoutBillingTiresProcess = (
  customerId: string,
  locationId: string,
  contractId: any,
  pagination: any,
  sortOrder: any,
  refreshTable: any,
  file?: any) => {
  const formData = new FormData();
  formData.append('customerId', customerId);
  formData.append('contractId', contractId);
  formData.append('paginationPageSize', pagination?.pageSize);
  formData.append('paginationPageNumber', pagination?.pageNumber);
  formData.append('sortOrderColumn', sortOrder?.column);
  formData.append('sortOrderOrder', sortOrder?.order);
  formData.append('refreshTable', refreshTable);
  if (locationId)
    formData.append('locationId', locationId);
  if (file) {
    formData.append('fromFile', 'true');
    formData.append('file', file);
  }
  else {
    formData.append('fromFile', 'false');
  }
  return billingTestInstance.post('/closeout-billing/getlist', formData);
};

const getStatus = (customerId: any, contractId: any ) =>
  billingTestInstance.post('/closeout-billing/status', { customerId, contractId });

const getCloseoutBillingTiresv2 = (customerId: string, contractId: any, locationId: string,  pagination: any, sortOrder: any) =>
  billingTestInstance.post('/closeout-billing/closeoutlist', {customerId, contractId, locationId, pagination, sortOrder});

const finalizeTermStatus = (payload: {
  contractId: string
}) =>
  billingTestInstance.post('/maintain-contract/finalizestatus', payload);

export default {
  getAvailableInvoices,
  getInvoiceTypes,
  getInvoiceStatuses,
  deleteAvailableInvoices,
  getViewInvoiceCustomerDetails,
  postHold,
  postRevert,
  postRelease,
  bulkRelease,
  bulkReleaseStatus,
  bulkReleaseResults,
  getMaintainInvoiceCustomerDetails,
  getMaintainInvoiceContractDetails,
  getCreditAgainst,
  printExcelInvoices,
  printPdfInvoices,
  getContractList,
  getInvoiceDetails,
  getBillingTypes,
  getInvoiceItems,
  createInvoice,
  addInvoiceItem,
  updateInvoiceItem,
  deleteInvoiceItem,
  saveInvoice,
  printExcelInvoiceItems,
  printPdfInvoiceItems,
  getAvailableQuotesByCustomer,
  deleteQuotes,
  listPostMileageAdjustments,
  addPostMileageAdjustments,
  updatePostMileageAdjustments,
  deletePostMileageAdjustments,
  saveCommentsPostMileage,
  calculateMiles,
  approveMiles,
  submitMiles,
  printExcelPostMileage,
  printPDFPostMileage,
  getPendingMileageRuns,
  printExcelPendingMileageRuns,
  printPdfPendingMileageRuns,
  estimatePendingMileageRuns,
  getBillingCodesList,
  updateBillingCodesList,
  updateBillingOptionsList,
  createQuote,
  getQuoteDetails,
  updateQuoteDetails,
  getTiresQuote,
  setCloseoutBillingDate,
  getCloseoutBillingTires,
  getCloseoutBillingErrors,
  submitCloseoutBilling,
  readyToBillCloseoutBilling,
  printExcelCloseoutBilling,
  printPdfCloseoutBilling,
  printInvoiceReport,
  printInvoiceReportExcel,
  addNewTires,
  uploadTreadDepth,
  deleteTires,
  saveQuote,
  verifyQuote,
  releaseQuote,
  viewErrors,
  printExcelViewTireSales,
  printPdfViewTireSales,
  printExcelTireSaleQuotes,
  printPdfViewTireSaleQuotes,
  getBackoutDetails,
  startBackout,
  getBillingOptionsList,
  getCloseoutBillingContractDetails,
  getApplicableInvoiceTypes,
  getAditionalDetails,
  getDefaultInvoiceValues,
  setBillingConditions,
  getBillingConditions,
  getTotalAndDescription,
  getMBCode,
  updateCloseoutBilling,
  finalizeTerm,
  getCreditAgainstInvoices,
  getTireSaleQuoteVehicles,
  getMyReports,
  getAvailableReports,
  downloadAvailableReport,
  downloadMyReport,
  getReportById,
  downloadReportById,
  deleteReports,
  getRegionList,
  copyInvoices,
  startBackoutStatus,
  startBackoutDetails,
  getRecordPage,
  updateSoldAndLostTire,
  getCloseoutBillingTiresProcess,
  getStatus,
  getCloseoutBillingTiresv2,
  finalizeTermStatus,
};
