import { HeadCell } from '../../../../../../shared/DataGridComponent/DataGridModels';

export const ORDER_DETAILS_FIELDS = {
  orderNumberDataField: {
    id: 'orderNumber',
    label: 'Order #',
    name: 'orderNumber',
    type: 'text',
    disabled: true,
  },
  dateShippedDataField: {
    id: 'dateShipped',
    label: 'Date Shipped',
    name: 'dateShipped',
    type: 'date',
    disabled: true,
  },
  invoiceNumberDataField: {
    id: 'invoiceNumber',
    label: 'Invoice #',
    name: 'invoiceNumber',
    type: 'text',
    disabled: true,
  },
  dlDataField: {
    id: 'dl',
    label: 'DL #',
    name: 'dl',
    type: 'text',
    disabled: true,
  },
};

export const RECEIVING_RECORD_FIELDS = {
  trrDocNumDataField: {
    id: 'trrDocNum',
    label: 'DN Doc #',
    name: 'trrDocNum',
    type: 'text',
    required: true,
  },
  receivedDateField: {
    id: 'receviedDate',
    label: 'Received Date',
    name: 'receviedDate',
    type: 'date',
    required: true,
  },
};

export const SERIES_FIELDS = {
  beginPrefixDataField: {
    id: 'beginPrefix',
    description: 'Prefix',
    name: 'beginPrefix',
    type: 'text',
  },
  beginBrandDataField: {
    id: 'beginBrand',
    description: 'Brand',
    name: 'beginBrand',
    type: 'text',
  },
  beginSufixDataField: {
    id: 'beginSufix',
    description: 'Sufix',
    name: 'beginSufix',
    type: 'text',
  },
  endPrefixDataField: {
    id: 'endPrefix',
    description: 'Prefix',
    name: 'endPrefix',
    type: 'text',
    disabled: true,
  },
  endBrandDataField: {
    id: 'endBrand',
    description: 'Brand',
    name: 'endBrand',
    type: 'text',
  },
  endSufixDataField: {
    id: 'endSufix',
    description: 'Sufix',
    name: 'endSufix',
    type: 'text',
    disabled: true,
  },
};

export const availableTiresColumns: HeadCell[] = [
  {
    key: 'column0',
    name: 'Brand Prefix',
    fieldName: 'intireBrndPfx',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Brand Number',
    fieldName: 'intireBrndNum',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Brand Suffix',
    fieldName: 'intireBrndSfx',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Type Code',
    fieldName: 'typeCodeFK',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Tire Class',
    fieldName: 'tc',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'DOT #',
    fieldName: 'dotNum',
    typeField: 'text',
  },
];

export const updatedConsignedTiresColumns: HeadCell[] = [
  {
    key: 'column0',
    name: 'Brand Prefix',
    fieldName: 'intireBrndPfx',
    typeField: 'text',
  },
  {
    key: 'column1',
    name: 'Brand Number',
    fieldName: 'intireBrndNum',
    typeField: 'text',
  },
  {
    key: 'column2',
    name: 'Brand Suffix',
    fieldName: 'intireBrndSfx',
    typeField: 'text',
  },
  {
    key: 'column3',
    name: 'Type Code',
    fieldName: 'typeCodeFK',
    typeField: 'text',
  },
  {
    key: 'column4',
    name: 'Tire Class',
    fieldName: 'tc',
    typeField: 'text',
  },
  {
    key: 'column5',
    name: 'DOT #',
    fieldName: 'dotNum',
    typeField: 'text',
  },
  {
    key: 'column6',
    name: 'DN Received',
    fieldName: 'recvDt',
    typeField: 'date',
  },
];

export const intransitTRRPageSizes = [500, 1000];

export interface IOrderData {
  orderId?: string,
  orderItemId?: string,
  gdyrDelNo?: string,
  invcNo?: string,
  dataShipped?: string,
}
